import { Fragment, useEffect } from "react";
import useReloadDummy from "../../../scripts/hooks/use.reload.dummy";
import Table from "../table";

const RenderTables = ( props ) => {

  const [ Dummy, reloadDummy ] = useReloadDummy();

  const {

    tables = [],
    canEdit = true,
    areaScale,
    renameTable,
    removeTable,
    addColumn,
    editColumn,
    removeColumn,
    updateTablePosition,
    updateRowsPosition,
    setDisablePinchZoom,
    setTablesOnTopLayer

  } = props;

  useEffect(() => { reloadDummy(); }, [ tables ])

  return (

    <Fragment>

      <Dummy />

      { tables.map(( table, key ) => (

        <Table

          key = { key }
          id = { table.id }
          name = { table.name }
          color = { table.color }
          canEdit = { canEdit }
          areaScale = { areaScale }
          columns = { table.columns }
          tablesLength = { tables.length }
          position = { table.position }
          renameTable = { renameTable }
          removeTable = { removeTable }
          addColumn = { addColumn }
          editColumn = { editColumn }
          removeColumn = { removeColumn }
          updateTablePosition = { updateTablePosition }
          updateRowsPosition = { updateRowsPosition }
          setDisablePinchZoom = { setDisablePinchZoom }
          setTablesOnTopLayer = { setTablesOnTopLayer }

        />

      ))} 

    </Fragment>

  );

}

export default RenderTables;