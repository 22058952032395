import React from "react";
import { Link } from 'react-router-dom';
import cssIf from "../../../scripts/helpers/class.add.if";
import './button.scss';

const Button = ({ children, action = false, disabled = false, color = "blue", className = "" }) => {

  return (

    <button 

      className={ `button button--${ color } ${ cssIf( disabled, "disabled" ) } ${ className }`}
      onClick={ () => { action && action() }}>

      { children }

    </button>

  );

}

export const ButtonRouter = ( props ) => (

  <Link to={ props.url }>
    <Button { ...props } />
  </Link>
  
)

export default Button;