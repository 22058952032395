import Xarrow from '../dynamic.arrows/xarrow';

const DynamicArrows = ({ data, table_id, zoom, isTablesOnTopLayer }) => ( data.length > 0 && 

  <div className="dynamicArrows">
          
    { data.map(( col, index ) => (

      col?.foreign &&

      <Xarrow

        key = {index}
        start = { `row-${ table_id }-${ col?.id }` } //can be string
        end = { `row-${ col.foreign.split('-')[ 0 ] }-${ col.foreign.split('-')[ 1 ] }` } //can be ref
        color = { "#2FBCB4" } //TODO color from props
        startAnchor = {['left', 'right']}
        endAnchor = {['left', 'right']}
        strokeWidth = { 3 * zoom }
        animateDrawing = { +col.foreign.split('-')[ 0 ] !== +table_id }
        curveness = {0.8}
        isSemiCircleStyle = { +col.foreign.split('-')[ 0 ] === +table_id } // если таблица ссылается сама на себя

      />

    ))}

  </div>

);

export default DynamicArrows;