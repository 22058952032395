const CURSOR = {

  MOUSE: 1,
  TOUCHPAD: 2

}

const SENSIVITY = {

  [ CURSOR.MOUSE ]: 0.5,
  [ CURSOR.TOUCHPAD ]: 0.06

}

export { CURSOR, SENSIVITY };