import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter, useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';

import Constructor from "./constructor";
import SignUp from "./sign/up";
import SignIn from "./sign/in";
import Tariff from "./tariff";
import PaymentResult from "./payment.result";
import useGlobal from "../../store";
import Schemes from "./schemes";

const AppRouter = () => {

  return (

    <BrowserRouter>

      <Routes>

        <Route path ="/" element = { <Protected subscribe><Schemes /></Protected> }/>
        <Route exact path ="/scheme/:id" element = { <Protected withoutLogout><Constructor /></Protected> }/>
        <Route exact path ="/signup" element = { <SignUp /> }/>
        <Route exact path ="/signin" element = { <SignIn /> }/>
        <Route exact path ="/tariff" element = { <Protected><Tariff /></Protected> }/>
        <Route exact path ="/payment" element = { <Protected><PaymentResult /></Protected> }/>

      </Routes>
      
    </BrowserRouter>

  );

}

const Protected = ({ children, subscribe, withoutLogout = false }) => {

	const [ globalState, globalActions ] = useGlobal();
  const navigate = useNavigate();
	const token = cookie.load('token');

	useEffect(() => { 
    ////console.log(`Protected token = `, token);
		if ( token ) {
      
      globalActions.sign.Check( token, subscribe, navigate, withoutLogout )
    
    } else if ( !withoutLogout ) {

      globalActions.sign.Out()
      
    }
	
	}, []);

	return children;

}

export default AppRouter;