import React, { useLayoutEffect, useRef, useState } from "react";
import _ from 'lodash';
import { arrowShapes, cAnchorEdge, cArrowShapes } from "../xconstants";
import { getElemPos, getElementByPropGiven, xStr2absRelative } from "./utils";

function parseLabels( label ) {

  let parsedLabel = { start: null, middle: null, end: null };

  if ( label ) {

    if ( typeof label === 'string' || React.isValidElement( label ) ) {
      
      parsedLabel.middle = label;
    
    } else {

      for ( let key in label ) {

        parsedLabel[ key ] = label[ key ];

      }

    }

  }

  return parsedLabel;

}

function parseAnchor( anchor ) {

  let anchorChoice = Array.isArray(anchor) ? anchor : [anchor];

  //convert to array of objects
  let anchorChoice2 = anchorChoice.map((anchorChoice) => {
    if (typeof anchorChoice === 'string') {
      return { position: anchorChoice };
    } else return anchorChoice;
  });

  //remove any invalid anchor names
  anchorChoice2 = anchorChoice2.filter((an) => cAnchorEdge.includes(an.position));
  if (anchorChoice2.length == 0) anchorChoice2 = [{ position: 'auto' }];

  //replace any 'auto' with ['left','right','bottom','top']
  let autosAncs = anchorChoice2.filter((an) => an.position === 'auto');
  if (autosAncs.length > 0) {
    anchorChoice2 = anchorChoice2.filter((an) => an.position !== 'auto');
    anchorChoice2.push(
      ...autosAncs.flatMap((anchorObj) => {
        return (['left', 'right', 'top', 'bottom']).map((anchorName) => {
          return { ...anchorObj, position: anchorName };
        });
      })
    );
  }

  // default values
  let anchorChoice3 = anchorChoice2.map((anchorChoice) => {
    if (typeof anchorChoice === 'object') {
      let anchorChoiceCustom = anchorChoice;
      if (!anchorChoiceCustom.position) anchorChoiceCustom.position = 'auto';
      if (!anchorChoiceCustom.offset) anchorChoiceCustom.offset = { x: 0, y: 0 };
      if (!anchorChoiceCustom.offset.y) anchorChoiceCustom.offset.y = 0;
      if (!anchorChoiceCustom.offset.x) anchorChoiceCustom.offset.x = 0;
      return anchorChoiceCustom;
    } else return anchorChoice;
  });

  return anchorChoice3;

}

function parseDashness( dashness, props ) {

  let dashStroke = 0,
    dashNone = 0,
    animDashSpeed,
    animDirection = 1;
  if (typeof dashness === 'object') {
    dashStroke = dashness.strokeLen || props.strokeWidth * 2;
    dashNone = dashness.strokeLen ? dashness.nonStrokeLen : props.strokeWidth;
    animDashSpeed = dashness.animation ? dashness.animation : null;
  } else if (typeof dashness === 'boolean' && dashness) {
    dashStroke = props.strokeWidth * 2;
    dashNone = props.strokeWidth;
    animDashSpeed = null;
  }
  return { strokeLen: dashStroke, nonStrokeLen: dashNone, animation: animDashSpeed, animDirection }

}

function parseEdgeShape( svgEdge ) {

  if (typeof svgEdge == 'string') {
    if (svgEdge in arrowShapes) svgEdge = arrowShapes[svgEdge];
    else {
      console.warn(
        `'${svgEdge}' is not supported arrow shape. the supported arrow shapes is one of ${cArrowShapes}.
           reverting to default shape.`
      );
      svgEdge = arrowShapes['arrow1'];
    }
  }
  
  if (svgEdge?.offsetForward === undefined) svgEdge.offsetForward = 0.25;
  if (svgEdge?.svgElem === undefined) svgEdge.svgElem = 'path';
  // if (svgEdge?.svgProps === undefined) svgEdge.svgProps = arrowShapes.arrow1.svgProps;
  return svgEdge;

}

function parseGridBreak( gridBreak ) {

  let resGridBreak = xStr2absRelative(gridBreak);
  if (!resGridBreak) resGridBreak = { relative: 0.5, abs: 0 };
  return resGridBreak;

}

function withUpdate( propVal, updateRef ) {

  if ( updateRef ) updateRef.current = true;
  return propVal;

}

const noParse = userProp => userProp;
const noParseWithUpdatePos = (userProp, _, updatePos) => withUpdate(userProp, updatePos);
const parseNumWithUpdatePos = (userProp, _, updatePos) => withUpdate(Number(userProp), updatePos);
const parseNum = (userProp) => Number(userProp);

const parsePropsFuncs = {

  start: (userProp) => getElementByPropGiven(userProp),
  end: (userProp) => getElementByPropGiven(userProp),
  startAnchor: (userProp, _, updatePos) => withUpdate(parseAnchor(userProp), updatePos),
  endAnchor: (userProp, _, updatePos) => withUpdate(parseAnchor(userProp), updatePos),
  labels: (userProp) => parseLabels(userProp),
  color: noParse,
  lineColor: (userProp, propsRefs) => userProp || propsRefs.color,
  headColor: (userProp, propsRefs) => userProp || propsRefs.color,
  tailColor: (userProp, propsRefs) => userProp || propsRefs.color,
  strokeWidth: parseNumWithUpdatePos,
  showHead: noParseWithUpdatePos,
  headSize: parseNumWithUpdatePos,
  showTail: noParseWithUpdatePos,
  tailSize: parseNumWithUpdatePos,
  path: noParseWithUpdatePos,
  curveness: parseNumWithUpdatePos,
  gridBreak: (userProp, _, updatePos) => withUpdate(parseGridBreak(userProp), updatePos),
  // // gridRadius = strokeWidth * 2, //todo
  dashness: (userProp, propsRefs) => parseDashness(userProp, propsRefs),
  headShape: (userProp) => parseEdgeShape(userProp),
  tailShape: (userProp) => parseEdgeShape(userProp),
  showXarrow: noParse,
  animateDrawing: noParse,
  zIndex: parseNum,
  passProps: noParse,
  arrowBodyProps: noParseWithUpdatePos,
  arrowHeadProps: noParseWithUpdatePos,
  arrowTailProps: noParseWithUpdatePos,
  SVGcanvasProps: noParseWithUpdatePos,
  divContainerProps: noParseWithUpdatePos,
  divContainerStyle: noParseWithUpdatePos,
  SVGcanvasStyle: noParseWithUpdatePos,
  _extendSVGcanvas: noParseWithUpdatePos,
  _debug: noParseWithUpdatePos,
  _cpx1Offset: noParseWithUpdatePos,
  _cpy1Offset: noParseWithUpdatePos,
  _cpx2Offset: noParseWithUpdatePos,
  _cpy2Offset: noParseWithUpdatePos,

};

const propsDeps = {};

for ( let propName in parsePropsFuncs ) {

  propsDeps[ propName ] = [ propName ];

}

for ( let propName of [ 'lineColor', 'headColor', 'tailColor' ]) {

  propsDeps[ propName ].push('color');

}

function parseGivenProps( props, propsRef ) {

  for ( let [ name, val ] of Object.entries( props ) ) {

    propsRef[ name ] = parsePropsFuncs?.[ name ]?.( val, propsRef );

  }

  return propsRef;

}

const defaultProps = {

  start: null,
  end: null,
  startAnchor: 'auto',
  endAnchor: 'auto',
  labels: null,
  color: 'CornflowerBlue',
  lineColor: null,
  headColor: null,
  tailColor: null,
  strokeWidth: 4,
  showHead: true,
  headSize: 6,
  showTail: false,
  tailSize: 6,
  path: 'smooth',
  curveness: 0.8,
  gridBreak: '50%',
  // gridRadius : strokeWidth * 2, //todo
  dashness: false,
  headShape: 'arrow1',
  tailShape: 'arrow1',
  showXarrow: true,
  animateDrawing: false,
  zIndex: 0,
  passProps: {},
  arrowBodyProps: {},
  arrowHeadProps: {},
  arrowTailProps: {},
  SVGcanvasProps: {},
  divContainerProps: {},
  divContainerStyle: {},
  SVGcanvasStyle: {},
  _extendSVGcanvas: 0,
  _debug: false,
  _cpx1Offset: 0,
  _cpy1Offset: 0,
  _cpx2Offset: 0,
  _cpy2Offset: 0,

}

let initialParsedProps = {};
initialParsedProps = parseGivenProps( defaultProps, initialParsedProps );

const initialValVars = {

  startPos: { x: 0, y: 0, right: 0, bottom: 0 },
  endPos: { x: 0, y: 0, right: 0, bottom: 0 },

};

const deepCompareEquals = ( a, b ) => _.isEqual( a, b );

function useDeepCompareMemoize( value ) {

  const ref = useRef();

  if ( !deepCompareEquals( value, ref.current ) ) {

    ref.current = value;

  }

  return ref.current;

}

function useDeepCompareEffect( callback, dependencies ) {

  useLayoutEffect( callback, dependencies.map( useDeepCompareMemoize ) )

}

const useXarrowProps = ( userProps, { headRef, tailRef }) => {

  const [ propsRefs, setPropsRefs ] = useState( initialParsedProps );
  const shouldUpdatePosition = useRef( false );
  propsRefs['shouldUpdatePosition'] = shouldUpdatePosition;
  const curProps = { ...defaultProps, ...userProps };

  for ( let propName in defaultProps ) {

    // eslint-disable-next-line
    useLayoutEffect(() => {

      propsRefs[ propName ] = parsePropsFuncs?.[ propName ]?.(

        curProps[ propName ],
        propsRefs,
        shouldUpdatePosition
      
      );

      setPropsRefs({ ...propsRefs });

    }, propsDeps[ propName ].map( name => userProps[ name ] ) );

  }

  const [ valVars, setValVars ] = useState( initialValVars );
  const startPos = getElemPos( propsRefs.start );

  useDeepCompareEffect(() => {

    valVars.startPos = startPos;
    shouldUpdatePosition.current = true;
    setValVars({ ...valVars });

  }, [ startPos ]);

  const endPos = getElemPos( propsRefs.end );

  useDeepCompareEffect(() => {

    valVars.endPos = endPos;
    shouldUpdatePosition.current = true;
    setValVars({ ...valVars });

  }, [ endPos ]);

  useLayoutEffect(() => {

    shouldUpdatePosition.current = true;
    setValVars({ ...valVars });

  }, [
    
    propsRefs.headShape.svgElem,
    propsRefs.tailShape.svgElem

  ]);

  return [ propsRefs, valVars ];

}

export default useXarrowProps;