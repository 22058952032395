
import useGlobal from '../../../store';
import s from "./popup.confirm.module.scss";

import { 
  
  usePopup,
  PopupTitle, 
  PopupActionButtons, 
  PopupContentPadding, 

} from '../popup';

const PopupConfirm = ( props ) => {

  const [ globalState, globalActions ] = useGlobal();

  const { 

    popup_confirm_title,
    popup_confirm_text,
    popup_confirm_icon,
    popup_confirm_function 

  } = globalState;

  const { popup } = globalActions;

  async function handleConfirm() {

    popup_confirm_function();
    popup.hide();

  }

  const CONTENT = (

    <div className = { s.popup }>

      <PopupTitle>{ popup_confirm_title }</PopupTitle>

      <PopupContentPadding>{ popup_confirm_text }</PopupContentPadding>

      <PopupActionButtons

        confirmText = "Подтвердить"
        confirm = { handleConfirm }
        cancel = { popup.hide }

      />

    </div>

  );

  const thisPopup = usePopup({ name: "confirm", content: CONTENT })

  return thisPopup;

}

export default PopupConfirm;