import s from "./button.block.remove.module.scss";
import { TrashIcon } from "./icons";

const ButtonBlockRemove = ({ action = false, tip = "Удалить", className = "" }) => (

  <button

    title = { tip }
    className = {`${ s.button } ${ className } pointer`}
    onClick = { ( e ) => { action && action(); e.stopPropagation(); } }
  
  >

    <TrashIcon />

  </button>

);

export { ButtonBlockRemove };