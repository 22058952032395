import React from 'react';
import Header from '../header';
import './container.scss';

const Container = ({ className, children, popups = "" }) => {
  
  return (

    <div className = {`container ${className}`}>

      { popups }

      <Header />

      { children }

    </div>
    

  );

}

export default Container;