import { Fragment } from 'react';
import useGlobal from '../../../store';
import cssIf from "../../../scripts/helpers/class.add.if";
import Button from "../../components/button";
import "./popup.scss";

const Popup = ({ children, isOpened, animateClose, withoutBackground = false }) => {

  return (

    !isOpened ? <Fragment /> :
    
    <div className = {`modal ${ 'popup-container' } ${ cssIf( isOpened, `popup-container--opened` ) }`}>

      <div className = { "popup-background" } />

      <div className = {`popup ${ cssIf( withoutBackground, `popup--without-background` ) } ${ cssIf( animateClose, 'zoomOut' ) }`}>

        { children }

      </div>
      
    </div>

  );

}

const PopupTitle = ({ children }) => (

  <div className = "popup__title">{ children }</div>

);

const PopupContentPadding = ({ children }) => (

  <div className = "popup__padding">{ children }</div>

);

const PopupActionButtons = ({ confirm = () => {}, cancel = () => {}, confirmText = "OK", cancelText = "Отмена", confirmDisabled = false, cancelDisabled = false, hideCancel = false }) => (

  <div className = {`popup__action_buttons w-100 flex items-center justify-end`}>

    <Button
      
      color = "blue"
      action = { confirm }
      disabled = { confirmDisabled }

    >
      
      { confirmText }

    </Button>

    { !hideCancel &&
    
      <Button
        
        color = "gray"
        action = { cancel }
        disabled = { cancelDisabled }

      >
        
        { cancelText }

      </Button>
      
    }
  
  </div>

);

const usePopup = ({ name, content, settings = {} }) => {

  const {

    withoutBackground = false,

  } = settings;

  const [ globalState, globalActions ] = useGlobal();
  const { popup_opened, popup_animate_close } = globalState;
  const { popup } = globalActions;

  const show = () => popup.show( name );
  const hide = () => popup.hide();

  const initialize = (

    <Popup 
      isOpened = { popup_opened === name }
      animateClose = { popup_animate_close }
      withoutBackground = { withoutBackground }
    >

      { content }

    </Popup>

  )

  return {

    show, 
    hide,
    initialize

  }

}

export {

  usePopup,
  PopupTitle,
  PopupContentPadding,
  PopupActionButtons,

}