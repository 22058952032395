import React from 'react';
import useGlobal from '../../../store';
import './tariff.bar.scss';

const TariffBar = ({ data }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { name, duration, remaining } = data;

  const current = duration - remaining;
  const percent = current / duration * 100;
  //// const daytext = current >= 5 ? "дней" : current > 1 ? "дня" : "день";
  const daytext = "день";

  return (

    <div className="tariffbar flex justify-between items-center">

      <div className="tariffbar__content">
        Ваш тариф: <b>{ name }</b>
      </div>

      <div className="tariffbar__progress">
        <div className="tariffbar__progress__bar" style = {{ width: `${ percent }%` }} />
      </div>

      <div className="tariffbar__content">
        <b>{ current }</b> { daytext } из <b>{ duration }</b>
      </div>
      
    </div>

  );

}

export default TariffBar;