export function hexToRgb(hex, mode = '') {

  const 
  _hex = hex != undefined 
    ? hex.substr(0,1) === '#' 
      ? hex.substr(1) 
      : hex 
    : 'FFFFFF';
  
  const parse = _hex.match(/.{1,2}/g);

  let result = {
    r: parseInt(parse[0], 16),
    g: parseInt(parse[1], 16),
    b: parseInt(parse[2], 16)
  };

  switch (mode) {

    case 'lighter':
      result = {
        r: parseInt(result.r) - 20,
        g: parseInt(result.g) - 20,
        b: parseInt(result.b) - 20
      }

    case 'darker':
      result = {
        r: parseInt(result.r) + 20,
        g: parseInt(result.g) + 20,
        b: parseInt(result.b) + 20
      }

    default: break;

  }

  result = {
    r: result.r < 0 ? 0 : result.r,
    g: result.g < 0 ? 0 : result.g,
    b: result.b < 0 ? 0 : result.b
  }
  
  return result;

}

export function parseRGBA( rgba, mode ) {

  let arrFromString = rgba.replace("rgba(", "").replace(")", "").replace(/\s/g, '').split(",");

  let result = {
    
    r: parseInt( arrFromString[0] ),
    g: parseInt( arrFromString[1] ),
    b: parseInt( arrFromString[2] ),
    a: parseInt( arrFromString[3] ) || 1

  };

  switch (mode) {

    case 'lighter':
      result = {
        r: parseInt(result.r) - 20,
        g: parseInt(result.g) - 20,
        b: parseInt(result.b) - 20,
        a: result.a
      }

    case 'darker':
      result = {
        r: parseInt(result.r) + 20,
        g: parseInt(result.g) + 20,
        b: parseInt(result.b) + 20,
        a: result.a
      }

    default: break;

  }

  result = {

    r: result.r < 0 ? 0 : result.r,
    g: result.g < 0 ? 0 : result.g,
    b: result.b < 0 ? 0 : result.b,
    a: result.a < 0 ? 0 : result.a

  }
  
  return result;
  
}