import cssIf from "../../../scripts/helpers/class.add.if";
import "./options.scss";

const Options = ({ show, list, top = false, close = () => {} }) => (

  <div className={`options ${ cssIf( show, "opened" )}`} style = { top ? { top: `${ top }px`} : {} }>

    { list.map(( el, key ) => (
      
      <div
        key = { key }
        id = { el?.id || "" }
        onClick = { () => { !el?.disabled && el.action(); close(); }}
        className = {`options__element ${ cssIf( el?.disabled, `disabled` )} ${ cssIf( el?.hidden, `hidden` )}`}
        style = {{
          color: el?.color ? el.color : undefined
        }}
      >
        
        { el?.text ? el.text : el }

      </div>
      
    ))}

  </div>

);

export default Options;