const INDEX_TYPES = [
  {
    id: 1,
    name: `NORMAL`,
    tip: `Стандартный индекс по значению. Позволяет быстро находить строки, соответствующие определенным значениям или диапазонам значений.`
  },
  {
    id: 2,
    name: `FULLTEXT`,
    tip: `Полнотекстовый индекс. Подходит только для типов данных *TEXT и VARCHAR. Используется для выполнения поиска по ключевым словам или фразам в текстовом содержимом столбца.`
  },
  {
    id: 3,
    name: `SPATIAL`,
    tip: `Индекс пространственных данных. Подходит только для геометрических типов данных. Используется для эффективного поиска географических объектов и выполнения операций, таких как поиск ближайших объектов или определение зоны, попадающей в определенную область.`
  },
];

const INDEX_METHODS = [
  {
    id: 1,
    name: `B-TREE`,
    tip: `Самый распространенный метод индексации в MySQL, который поддерживает эффективный поиск по значениям. Он хранит ключи в отсортированном порядке на основе балансированного дерева`
  },
  {
    id: 2,
    name: `HASH`,
    tip: `Хеширование (Hash) - этот метод использует хеш-функции для вычисления хеш-значений ключей и создания хеш-таблицы для быстрого поиска. Однако, индексы на основе хеширования не поддерживают диапазонные запросы`
  },
  {
    id: 3,
    name: `GIN`,
    tip: `GIN (Generalized Inverted Index) - это метод индексации, который широко используется для полнотекстового и поиска по массивам данных в JSON или массивах`
  },
];

export {
  
  INDEX_TYPES,
  INDEX_METHODS
  
}