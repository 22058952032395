import Textfield from ".";
import { ReactComponent as SearchIcon } from "./icons/search.svg";

const Searchfield = ( props ) => {

  return (

    <Textfield

      { ...props }
      className = {`searchfield ${ props?.className }`}
      placeholder = "Поиск"
      icon = { <SearchIcon className = "searchIcon" /> }

    />

  );

}

export default Searchfield;