import axios from 'axios';
import cookie from 'react-cookies';
import fileFromUrl from '../../scripts/helpers/file.from.url';
import API from '../../settings/api';

export async function toMySQL( store, id, reload = false ) {
  
  const token = cookie.load('token');
  if ( !token ) { console.error(`Попытка разлогина из all scheme`); return false; }

  const params = {

    id, 
    token,
    mode: "sql"

  }

  const { status, data } = await axios.post( API.scheme.dumpSave, params );
  
  if ( status === 200 ) {
    
    fileFromUrl( data.download );
    console.log( 'Дамп создан' );

  } else {

    console.error( data.text );

  }

}