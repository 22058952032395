import { useEffect, useState } from "react";
import "./loading.scss";

const Loading = ( props ) => {

  const {

    text = "Ожидайте, данные загружаются",
    withoutText = false,
    theme = "dark"

  } = props;

  const [ dots, setDots ] = useState("");

  useEffect(() => {

    let _dots = ``

    const animation = setInterval(() => {

      _dots = _dots.length < 3 ? `${ _dots }.` : ``
      setDots( _dots )

    }, 500 );

    return () => {

      clearInterval( animation );

    }

  }, [])

  return (

    <div className = {`loading loading--${ theme }`}>

      <div className = "loading__loader" />

      { !withoutText &&

        <div className = {`loading__text relative`}>
          
          { text }<span className="absolute">{ dots }</span>
          
        </div>
        
      }

    </div>

  );

}

export { Loading }