import { useState, useEffect, useRef } from 'react';
import useGlobal from '../../../../store';
import ColumnSettings from './column.settings';
import DataTypeSelector from './data.type.selector';
import { DATA_TYPES } from '../../../../settings/data.types';
import { FOREIGN_ON_ACTIONS } from '../../../../settings/foreign.on.actions';
import useReloadDummy from '../../../../scripts/hooks/use.reload.dummy';
import { INDEX_METHODS, INDEX_TYPES } from '../../../../settings/index.enums';
import RelationSelector from './relation.selector';

const dataTypeDefaultSize = {

  0: 11,
  1: 255,
  2: "",
  3: "",
  4: "",
  5: "",
  6: "",
  7: "",
  8: "",
  9: "",
  10: "",
  11: "",
  12: "",
  13: "",
  14: ""

}

const Form = ( props ) => {

  const PAGE = {

    SETTINGS: 1,
    TYPE_SELECTOR: 2,
    RELATION_SELECTOR: 3
    
  }

  const [ page, setPage ] = useState( PAGE.SETTINGS );

  const [ Dummy, reloadDummy ] = useReloadDummy();

  const [ globalState, globalActions ] = useGlobal();
  const { tables } = globalState;

  const { isOpened = false, canEdit = true } = props;

  const refDOM = useRef( null );
  const refName = useRef( null );
  const refSize = useRef( null ); //? возможно не нужен
  const refDecimal = useRef( null ); //? возможно не нужен
  const refDefaultValue = useRef( null ); //? возможно не нужен

  const { id = "", action, close, closeRef } = props;

  const [ name, setName ] = useState( props.name || "" );
  const [ type, setType ] = useState( props.type || "" );
  const [ size, setSize ] = useState( props.size || "" );
  const [ decimal, setDecimal ] = useState( props.decimal || "" );
  const [ defaultValue, setDefaultValue ] = useState( props.defaultValue || "" );
  const [ comment, setComment ] = useState( props.comment || "" );

  const [ isUnique, setIsUnique ] = useState( props.isUnique || false );
  const [ isNotNull, setIsNotNull ] = useState( props.isNotNull || false );
  const [ isPrimaryKey, setIsPrimaryKey ] = useState( props.isPrimaryKey || false );
  const [ autoIncrement, setAutoIncrement ] = useState( props.autoIncrement || false );
  const [ isIndex, setIsIndex ] = useState( props.isIndex || false );
  const [ indexMethod, setIndexMethod ] = useState( props.indexMethod || false );
  const [ indexType, setIndexType ] = useState( props.indexType || false );

  const [ foreign, setForeign ] = useState( props.foreign || "" );
  const [ onDelete, setOnDelete ] = useState( props.onDelete || "" );
  const [ onUpdate, setOnUpdate ] = useState( props.onUpdate || "" );
  
  const typeSelector = {
    open: () => setPage( PAGE.TYPE_SELECTOR ),
    close: () => setPage( PAGE.SETTINGS )
  }

  const relationSelector = {

    open: () => setPage( PAGE.RELATION_SELECTOR ),

    close: () => {

      setPage( PAGE.SETTINGS );
      props.setDisablePinchZoom( false );

    }

  }

  function parseForeign( foreignRelation ) {

    const f_table_id = foreignRelation?.split('-')?.[ 0 ];
    const f_column_id = foreignRelation?.split('-')?.[ 1 ];

    if ( !f_table_id || !f_column_id ) { return false; }

    const t = tables.find( el => +el.id === +f_table_id );
    const column = t.columns.find( el => +el.id === +f_column_id );

    return {

      id: foreignRelation,
      table: { id: +t.id, name: t.name },
      column: { id: +column.id, name: column.name, type: column.type }

    }

  }

  function syncForeignAndTypeFromProps() {

    if ( props.type !== undefined && props.type !== false && props.type !== "" ) { 

      const findedType = DATA_TYPES?.find( t => parseInt( t.id ) === parseInt( props.type ) );
      
      setType({ 

        id: props.type, 
        name: findedType?.name || "",
        group: findedType?.group || ""

      });
      
    } else {

      setType("");

    }

    if ( props.foreign && props.foreign !== "" ) {
      
      const f = parseForeign( props.foreign );

      if ( !f ) { 
        
        setForeign(""); 
      
      } else {

        setForeign({ 

          id: f.id, 
          name: `${ f.table.name }.${ f.column.name }`
  
        });

      }  

    } else {

      setForeign("");
      
    }

    if ( props.onDelete !== undefined && props.onDelete !== false && props.onDelete !== "" ) { 
      
      setOnDelete({ 

        id: props.onDelete, 
        name: FOREIGN_ON_ACTIONS?.find( t => parseInt( t.id ) === parseInt( props.onDelete ) )?.name

      });
      
    } else {

      setOnDelete("");

    }

    if ( props.onUpdate !== undefined && props.onUpdate !== false && props.onUpdate !== "" ) { 
      
      setOnUpdate({ 

        id: props.onUpdate, 
        name: FOREIGN_ON_ACTIONS?.find( t => parseInt( t.id ) === parseInt( props.onUpdate ) )?.name

      });
      
    } else {

      setOnUpdate("");

    }

    if ( props.indexMethod !== undefined && props.indexMethod !== false && props.indexMethod !== "" ) { 
      
      setIndexMethod({ 

        id: props.indexMethod, 
        name: INDEX_METHODS?.find( t => parseInt( t.id ) === parseInt( props.indexMethod ) )?.name

      });
      
    } else {

      setIndexMethod("");

    }

    if ( props.indexType !== undefined && props.indexType !== false && props.indexType !== "" ) { 
      
      setIndexType({ 

        id: props.indexType, 
        name: INDEX_TYPES?.find( t => parseInt( t.id ) === parseInt( props.indexType ) )?.name

      });
      
    } else {

      setIndexType("");

    }

    reloadDummy();

  }

  useEffect(() => {

    if ( isOpened ) {

      ( page !== PAGE.SETTINGS ) && setPage( PAGE.SETTINGS );

      canEdit && setTimeout(() => { refName?.current?.focus(); }, 150 );
      refDOM.current?.scrollIntoViewIfNeeded && refDOM.current.scrollIntoViewIfNeeded();

      props?.name ? ( props.name !== name ) && setName( props.name ) : setName("");
      props?.comment ? ( props.comment !== comment ) && setComment( props.comment ) : setComment("");
      props?.size ? ( props.size !== size ) && setSize( props.size ) : setSize("");
      props?.decimal ? ( props.decimal !== decimal ) && setDecimal( props.decimal ) : setDecimal("");
      props?.isUnique ? ( props.isUnique !== isUnique ) && setIsUnique( props.isUnique ) : setIsUnique( false );
      props?.isNotNull ? ( props.isNotNull !== isNotNull ) && setIsNotNull( props.isNotNull ) : setIsNotNull( false );
      props?.isPrimaryKey ? ( props.isPrimaryKey !== isPrimaryKey ) && setIsPrimaryKey( props.isPrimaryKey ) : setIsPrimaryKey( false );
      props?.autoIncrement ? ( props.autoIncrement !== autoIncrement ) && setAutoIncrement( props.autoIncrement ) : setAutoIncrement("");
      props?.defaultValue ? ( props.defaultValue !== defaultValue ) && setDefaultValue( props.defaultValue ) : setDefaultValue("");
      props?.isIndex ? ( props.isIndex !== isIndex ) && setIsIndex( props.isIndex ) : setIsIndex("");

      syncForeignAndTypeFromProps();

    }

  }, [ 

    isOpened,
    props.id, 
    props?.name, 
    props?.type, 
    props?.comment, 
    props?.foreign, 
    props?.onDelete, 
    props?.onUpdate, 
    props?.size, 
    props?.decimal, 
    props?.isUnique, 
    props?.isNotNull, 
    props?.isPrimaryKey, 
    props?.autoIncrement, 
    props?.defaultValue,
    props?.isIndex 

  ]);

  function handleSetType( t ) {

    setType( t );
    setSize( dataTypeDefaultSize[ t.id ] );
    typeSelector.close();

  }

  function handleAction() {

    if ( name === "" ) { alert("Название не может быть пустым!"); return; }

    canEdit && action({ 

      id, 
      name, 
      type: type.id, 
      foreign: foreign.id, 
      onDelete: onDelete.id,
      onUpdate: onUpdate.id,
      size, 
      decimal,
      isNotNull,
      isUnique,
      isPrimaryKey, 
      isIndex, 
      indexMethod: indexMethod.id,
      indexType: indexType.id,
      autoIncrement, 
      defaultValue, 
      comment 

    });

    handleClose();

  }  

  function handleClose() {

    if ( page === PAGE.TYPE_SELECTOR || page === PAGE.RELATION_SELECTOR ) {

      setPage( PAGE.SETTINGS );
      return;

    }

    // TODO check fields
    setName("");
    setType("");
    setSize("");
    setDecimal("");
    setForeign("");
    setOnDelete("");
    setOnUpdate("");
    setComment("");
    setIsNotNull("");
    setIsUnique("");
    setIsPrimaryKey("");
    setAutoIncrement("");
    setDefaultValue("");
    setIsIndex("");
    setIndexMethod("");
    setIndexType("");

    close();

  }

  closeRef.current = handleClose;

  const thisForm = useRef( null );

  const FORM = {

    [ PAGE.TYPE_SELECTOR ]: <DataTypeSelector select = { handleSetType } />,

    [ PAGE.RELATION_SELECTOR ]: (
    
      <RelationSelector
      
        foreign = { foreign }
        setForeign = { setForeign }
        setType = { setType }
        close = { relationSelector.close }
        setDisablePinchZoom = { props.setDisablePinchZoom }
        
      />
      
    ),

    [ PAGE.SETTINGS ]: (

      <ColumnSettings

        canEdit = { canEdit }
        refDOM = { refDOM }

        name = { name }
        setName = { setName }
        refName = { refName }

        type = { type }
        handleSetType = { handleSetType }

        foreign = { foreign }
        onDelete = { onDelete }
        setOnDelete = { setOnDelete }
        onUpdate = { onUpdate }
        setOnUpdate = { setOnUpdate }

        defaultValue = { defaultValue }
        setDefaultValue = { setDefaultValue }
        refDefaultValue = { refDefaultValue }

        size = { size }
        setSize = { setSize }
        refSize = { refSize }

        decimal = { decimal }
        setDecimal = { setDecimal }
        refDecimal = { refDecimal }

        isPrimaryKey = { isPrimaryKey }
        setIsPrimaryKey = { setIsPrimaryKey }
        
        autoIncrement = { autoIncrement }
        setAutoIncrement = { setAutoIncrement }

        isNotNull = { isNotNull }
        setIsNotNull = { setIsNotNull }

        isUnique = { isUnique }

        setIsUnique = { bool => { 

          setIsUnique( bool ); 
          if ( bool ) { setIsIndex( false ); } 
        
        }}

        isIndex = { isIndex }
        setIsIndex = { setIsIndex }
        indexMethod = { indexMethod }
        setIndexMethod = { setIndexMethod }
        indexType = { indexType }
        setIndexType = { setIndexType }

        comment = { comment }
        setComment = { setComment }

        handleAction = { handleAction }
        openTypeSelector = { typeSelector.open }
        openRelationSelector = { relationSelector.open }

        setDisablePinchZoom = { props.setDisablePinchZoom }

      />

    )

  }

  return (
    
    <div 
    
      className = {`table__form table: ${ props.thisTable?.clientWidth } form: ${ thisForm.current?.clientWidth } result = ${ props.thisTable?.clientWidth - thisForm.current?.clientWidth }`}
      ref = { thisForm }
      style = {{
        left: `${( props.thisTable?.clientWidth - thisForm.current?.clientWidth ) / 2 }px`
      }}
      
    >

      <Dummy />

      <div className = "table__form__cutout" />

      { FORM[ page ] }

    </div>

  );

}

export default Form;