import { Fragment } from "react";
import { DATA_TYPES_GROUPS } from "../../../../../settings/data.types";
import "./data.type.selector.scss";

const DataTypeSelector = ({ select }) => {

  const COL = [
    [],
    [ DATA_TYPES_GROUPS[ 0 ], DATA_TYPES_GROUPS[ 1 ], DATA_TYPES_GROUPS[ 5 ] ],
    [ DATA_TYPES_GROUPS[ 2 ], DATA_TYPES_GROUPS[ 4 ] ],
    [ DATA_TYPES_GROUPS[ 6 ], DATA_TYPES_GROUPS[ 7 ] ],
    [ DATA_TYPES_GROUPS[ 3 ] ],
  ] 

  return (

    <div className = "dtform">

      <DataTypeColumn groups = { COL[ 1 ] } select = { select } />
      <DataTypeColumn groups = { COL[ 2 ] } select = { select } />
      <DataTypeColumn groups = { COL[ 3 ] } select = { select } />
      <DataTypeColumn groups = { COL[ 4 ] } select = { select } />


    </div>

  );

}

const DataTypeColumn = ({ groups, select }) => (

  <div className = "dtform__col">

    { groups.map(( group, key ) => (

      <Fragment key = { key }>

        <div className = "dtform__group" title = { group?.title }>{ group.name }</div>

        { group.types.map(( type, key ) => (

          <div 

            key = { key }
            className = {`dtform__type pointer`} //TODO light selected
            title = { type.tip }
            onClick = { () => select( type ) }

          >
            
            { type.name }
            
          </div>

        ))}

      </Fragment>

    ))}

  </div>

)

export default DataTypeSelector