import React, { useState, useRef, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import cssIf from "../../../scripts/helpers/class.add.if";
import focusOnRef from "../../../scripts/helpers/focus.on.ref";
import smartWidth from "../../../scripts/helpers/smart.input.width";
import useGlobal from "../../../store";
import Options from "../options";
import TariffBar from "../tariff.bar";
import { ReactComponent as BackIcon } from './icons/back.svg';
import { ReactComponent as HelpIcon } from './icons/help.svg';
import { ReactComponent as ArrowIcon } from './icons/arrow.svg';
import { ReactComponent as Logo } from './icons/logo.svg';
import { SHARE_ACCESS } from "../../../settings/constants";
import ToggleMouse from "../toggle.mouse";
import ToggleTheme from "../toggle.theme";
import './header.scss';

const Header = () => {

  const isClean = window.location.pathname.indexOf("sign") !== -1;

  const [ globalState, globalActions ] = useGlobal();

  const { 

    user_name, 
    user_tariff, 
    scheme_id, 
    scheme_name, 
    scheme_description, 
    scheme_access 

  } = globalState;

  const { 

    sign, 
    scheme, 
    popup, 
    changeStates, 
    exportDump 

  } = globalActions;

  const navigate = useNavigate();

  const [ showUserMenu, setShowUserMenu ] = useState( false );

  const USER_MENU = [
    {
      text: "Выйти",
      action: sign.Out
    }
  ]

  function handleBack() {

    scheme.resetProjectStates();
    navigate( user_tariff?.active ? '/' : '/tariff' );

  }

  return (

    <header>

      <div className = "header__aside header__left">

        <Logo className = "logo" onClick = { () => { window.location = "https://database-design.ru" }} />

        <ToggleTheme />

        {( scheme_name !== "" && user_name !== "" ) &&

          <div         
            className = "back-to-schemes flex items-center" 
            onClick = { handleBack }
          >

            <BackIcon />
            к списку схем
          
          </div>

        }

      </div>

      { !isClean && 
      
        <React.Fragment>
          
          <div className = "header__middle flex flex-1 justify-center">

            {( user_tariff?.active && scheme_name === "" )

              ? <TariffBar data = { user_tariff } />
            
              : scheme_name !== "" 
              
                && <SchemeName

                    title = { scheme_name }
                    description = { scheme_description }
                    access = { scheme_access }
                    edit = { scheme.rename }
                    exportDump = { () => user_tariff?.id > 0 ? exportDump.toMySQL( scheme_id ) : navigate(`/tariff`) }
                    share = { () => user_tariff?.id > 0 ? popup.show( "share" ) : navigate(`/tariff`) }
                    remove = { () => {

                      popup.confirm({
      
                        text: "Вы действительно хотите удалить проект и все его таблицы? Это действие необратимо!",
                        action: () => scheme.remove( scheme_id, () => navigate('/') )
                        
                      });
                      
                    }}

                  />
                
            } 

          </div>      

          <div className = "header__aside header__right">

            { scheme_name !== "" && <ToggleMouse /> }

            {( scheme_name !== "" && user_name !== "" ) &&

              <div className = "zoom_btns flex">

                <HelpIcon 

                  onClick = { () => { changeStates({ showHelpPopup: true }) }} 
                  title = "Краткая инструкция"

                />
                
              </div>

            }

            <div className = "user_container relative">               

              <div
                className = "user_name"
                onClick = { () => user_name && setShowUserMenu( !showUserMenu )}
              >

                { user_name 
                
                  ? user_name 

                  : (
                    
                    <div className = "flex items-center">

                      <div className = "slash" style = {{ marginLeft: 'unset' }} />
                      <span onClick = { () => { navigate('/signin') }}>Войти</span>
                      <div className = "slash"/>
                      <span onClick = { () => { navigate('/signup') }}>Зарегистрироваться</span>

                    </div>
                    
                  )
                
                }

              </div>

              { user_name && 
              
                <div 

                  className = {`arrow arrow-usermenu ${ cssIf( showUserMenu, "rotate-180" ) }`}
                  onClick = { () => setShowUserMenu( !showUserMenu )}

                >
                  <ArrowIcon />
                </div>
              
              }

              <Options list = { USER_MENU } show = { showUserMenu } />
            
            </div>
            
          </div>

        </React.Fragment>
        
      }

    </header>

  );

}

export default Header; 

const SchemeName = ({ title, description, access, edit, share, exportDump, remove }) => {
  
  const [ showProjectMenu, setShowProjectMenu ] = useState( false );

  const [ showEditName, setShowEditName ] = useState( false );
  const [ showEditDescription, setShowEditDescription ] = useState( false );

  const [ name, setName ] = useState( title );
  const [ descr, setDescr ] = useState( description );

  const [ nameWidth, setNameWidth ] = useState( title.length * 11 );
  const [ descrWidth, setDescrWidth ] = useState( description.length * 11 );
 
  useEffect(() => {

    showEditName && setNameWidth( smartWidth( nameRef.current.scrollWidth, name ) );
    showEditDescription && setDescrWidth( smartWidth( descrRef.current.scrollWidth, descr ) );

  }, [ name, descr, showEditName, showEditDescription ])

  function openSharePopup() {

    share();
    setShowProjectMenu( false );

  }

  const nameRef = useRef( null );
  const descrRef = useRef( null );
 
  const PROJECT_MENU = [
    {
      text: "сохранить SQL-дамп",
      action: exportDump || null
    },
    {
      text: "настройки общего доступа",
      action: access === SHARE_ACCESS.EDIT && openSharePopup,
      hidden: access === SHARE_ACCESS.READ
    },
    {
      color: "#F07B73",
      text: "удалить проект",
      action: SHARE_ACCESS.EDIT && remove,
      hidden: access === SHARE_ACCESS.READ
    }
  ]

  function handleEdit( type, value ) {

    if ( type === "name" && value === "" ) { alert("Название не может быть пустым!"); return false; }
    edit({ type, value });

    setShowEditName( false );
    setShowEditDescription( false );
    
  }

  return (

    <div className = "user_container relative"> 

      <div
        className = "user_name"
      >

        { !showEditName 

            ? <span onClick = { () => { if ( !showEditName ) { setShowEditName( true ); focusOnRef( nameRef ) } } }>{ name }</span>

            : <input 

                ref = { nameRef }
                type = "text" 
                value = { name } 
                onChange = { ( e ) => setName( e.target.value ) } 
                onBlur = { () => handleEdit( "name", name )}
                onKeyUp = { ( e ) => e.key == "Enter" 
                  ? handleEdit( "name", name ) 
                  : e.key == "Escape" && setShowEditName( false ) 
                }
                style = {{
                  width: `${ nameWidth }px`
                }}

              />
          
        }{ descr.length > 0 && 
          
          <React.Fragment> - { !showEditDescription 

            ? <span onClick = { () => { if ( !showEditDescription ) { setShowEditDescription( true ); focusOnRef( descrRef ) } } }>{ descr.toLowerCase() }</span>

            : <input 

                ref = { descrRef }
                type = "text" 
                value = { descr } 
                onChange = { ( e ) => setDescr( e.target.value ) } 
                onBlur = { () => handleEdit( "description", descr )}
                onKeyUp = { ( e ) => e.key == "Enter" 
                  ? handleEdit( "description", descr ) 
                  : e.key == "Escape" && setShowEditDescription( false ) 
                }
                style = {{
                  width: `${ descrWidth }px`
                }}

              /> 
              
          } </React.Fragment> 
          
        }

      </div>

      <div 
        className={`arrow ${ cssIf( showProjectMenu, "rotate-180" ) }`}
        onClick = { () => setShowProjectMenu( !showProjectMenu )}
      >

        <ArrowIcon />

      </div>

      <Options list = { PROJECT_MENU } show = { showProjectMenu } close = { () => setShowProjectMenu( false ) } />
    
    </div>

  );

}