function focusOnRef( refDOM = false, seconds = 150 ) {

  setTimeout(() => {

    refDOM && refDOM?.current?.focus()

  }, seconds )

}

export default focusOnRef;