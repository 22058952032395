import React, { useState, useRef, useEffect, useMemo } from 'react';
import cookie from 'react-cookies';
import useXarrow from '../dynamic.arrows/useXarrow';
import { RandomHash } from '../../../scripts/hooks/use.reload.dummy';
import Draggable from 'react-draggable';
import { arrayMove } from 'react-sortable-hoc';
import SortableRows from './rows';
import Form from './form';
import ColorPicker from '../colorpicker';
import smartWidth from '../../../scripts/helpers/smart.input.width';
import cssIf from '../../../scripts/helpers/class.add.if';
import { apx1 } from '../../../scripts/helpers/apx';
import { DATA_TYPES } from '../../../settings/data.types';

import { ReactComponent as TableIcon } from './icons/table.svg';
import { ReactComponent as AnchorIcon } from './icons/anchor.svg';
import { ReactComponent as ConfirmIcon } from './icons/confirm.svg';
import { ReactComponent as TrashIcon } from './icons/trash.svg';
import { ReactComponent as ArrowDownIcon } from './icons/arrow.down.svg';

import './table.scss';

const Table = ( props ) => {

  const {

    id, 
    position, 
    name, 
    columns, 
    color = "", 
    canEdit = true,
    renameTable, 
    removeTable, 
    addColumn, 
    editColumn, 
    removeColumn, 
    updateTablePosition,
    updateRowsPosition,
    setDisablePinchZoom,
    setTablesOnTopLayer,
    tablesLength = 0

  } = props;

  const closeRef = useRef( null );
  const thisTable = useRef( null );

  const [ showForm, setShowForm ] = useState( false );
  const [ showComments, setShowComments ] = useState( false );

  const [ editData, setEditData ] = useState({ });
  const [ newRandomIfColumnEdited, setNewRandomIfColumnEdited ] = useState(0);

  const updateXarrow = useXarrow();

  function openEditForm( data ) {

    console.log( `openEditForm`, data );

    setShowForm( false );
    setEditData({});
    setEditData( data );
    setShowForm( true );
    setTablesOnTopLayer( true );
    
  }

  const closeEditForm = () => { setEditData({}); setShowForm( false ); setTablesOnTopLayer( false ); }

  function makeColumn( data ) {

    if ( editData?.id ) { //edit column

      editColumn( id, editData.id, data );
      setEditData({});

    } else { //add column

      addColumn( id, data );

    }

    setNewRandomIfColumnEdited( RandomHash() );

  }
 
  function stopDragging( e ) {

    const tableDOM = document.getElementById(`table-${ id }`);
    if ( !tableDOM ) { return; }

    const pos = tableDOM.style.transform.toString().substring(0, tableDOM.style.transform.toString().length - 1 ).substring( 10 );
    
    const position = {
      x: pos.split(',')[ 0 ].replace( "px", "" ),
      y: pos.split(',')[ 1 ].substring( 1 ).replace( "px", "" )
    }

    console.log(`New position table [ ${ name } ]:`, position );

    updateXarrow( e );
    updateTablePosition( id, position );

  }

  function handleMoveRow({ oldIndex, newIndex }) {
    
    const updated = arrayMove( columns, oldIndex, newIndex > 0 ? newIndex : 1 );
    updateRowsPosition( id, updated );
    setDisablePinchZoom( false );

  }

  function handleFormControlBtn() {

    if ( !showForm  ) {

      setShowForm( true ) 
      setTablesOnTopLayer( true )
    
    } else {
      
      closeRef.current()
      setDisablePinchZoom( false );
    
    }

  }

  const typeColumnWidth = useMemo(() => {

    const largestTypeName = columns.map( col => DATA_TYPES?.find( t => +t.id === +col.type )?.name ).sort( (a, b) => b.length - a.length )[ 0 ];
    const result = largestTypeName?.length > 6 ? `${( ( apx1 * 19 ) * largestTypeName.length ).toFixed( 4 ) }vw` : `${ ( apx1 * 125 ).toFixed( 4 ) }vw`;

    console.log(`table-${ id } recalculated typeColumnWidth:`, result );
    return result;

  }, [ columns?.length, newRandomIfColumnEdited ]); 

  return (

    <Draggable

      scale = { props.areaScale }
      handle = ".dragTable"
      disabled = { !canEdit }
      onStart = { () => { setDisablePinchZoom( true ) }}
      onDrag = { updateXarrow }
      onStop = { ( e ) => { stopDragging( e ); setDisablePinchZoom( false ); } }
      defaultPosition = {{
        
        x: parseInt( position.x ), 
        y: parseInt( position.y )

      }}

    >

      <div 
        id = {`table-${ id }`}
        ref = { thisTable }
        className = {`table ${ cssIf( showForm, 'table--form-is-opened' ) }`}
        onMouseEnter = { () => setTablesOnTopLayer( true ) }
        onMouseLeave = { () => !showForm && setTablesOnTopLayer( false ) }
      >

        {/* <div className="table__debug">{`table-${ id }`}</div> */}

        <TableTitle
          
          text = { name }
          color = { color }
          canEdit = { canEdit }
          tablesLength = { tablesLength }
          set = { ( new_name, new_color ) => { canEdit && renameTable( id, new_name, new_color ) }}
          removeTable = { () => canEdit && removeTable( id ) }
          setDisablePinchZoom = { setDisablePinchZoom }

        />        

        <div className="table__rows relative">
          
          { columns.length > 0 
          
            ? <SortableRows 
            
                table_id = { id }
                canEdit = { canEdit }
                data = { columns }
                typeColumnWidth = { typeColumnWidth }

                showComments = { showComments }
                openEditForm = { openEditForm }
                removeColumn = { ( tableID, columnID ) => {
                  
                  ( +editData?.id === +columnID ) && closeEditForm();
                  removeColumn( tableID, columnID );
                
                }}

                axis = "y"
                lockAxis = "y"
                useDragHandle = { true }
                lockToContainerEdges = { true }
                helperClass = "table__row--flying"
                onSortStart = { () => { 
                  
                  setDisablePinchZoom( true );
                  updateXarrow();
                  
                  const thisTable = document.getElementById(`table-${ id }`);
                  const currentScale = +cookie.load('dbd-scale');

                  if ( thisTable ) { 

                    let newScale = 1.0;
                    if ( currentScale < 1 ) { newScale = 1.0 + currentScale; }
                    thisTable.style.transform = `translate(${ position.x }px, ${ position.y }px) scale(${ newScale })`;

                  }
                                  
                }}
                onSortMove = { updateXarrow }
                onSortEnd = { ( attrs ) => { 

                  handleMoveRow( attrs );                  

                  const thisTable = document.getElementById(`table-${ id }`);

                  if ( thisTable ) { 
                    thisTable.style.transform = `translate(${ position.x }px, ${ position.y }px)`;
                  }

                  updateXarrow();

                }}
                
              />

            : <div className="table__row table__row_empty"><span>Добавьте столбцы</span></div>
          
          }
          
        </div>

        {( canEdit || showForm ) &&
          
          <div 

            onClick = { handleFormControlBtn }
            className = {`table__add ${ cssIf( columns.length % 2 !== 0, "table__add--light" ) }`} 

          >
            <AnchorIcon className = "anchorIcon" />

          </div>
        
        }
      
        <Form

          { ...editData }
          isOpened = { showForm }
          canEdit = { canEdit }
          action = { makeColumn }
          close = { closeEditForm } 
          setDisablePinchZoom = { setDisablePinchZoom }
          thisTable = { thisTable.current }
          closeRef = { closeRef }

        />           
      
      </div>

    </Draggable>

  );

}

const TableTitle = ({ text, color, set, removeTable, setDisablePinchZoom, canEdit = true, tablesLength }) => {

  const inputRef = useRef( null );
  const [ showInput, setShowInput ] = useState( false );
  const [ title, setTitle ] = useState( text );
  const [ background, setBackground ] = useState( color );
  const [ titleWidth, setTitleWidth ] = useState( ( text.length * 16 ) * apx1 );

  function startRenaming() {

    setShowInput( true );
    setDisablePinchZoom( true );
    setTimeout(() => { inputRef.current.focus(); }, 200 );

  }

  function handleKeyUp( e ) {
    
    if ( e.key === 'Enter' || e.key === 'Escape' ) {

      setShowInput( false );
      e.key === 'Enter' && set( title, background );
      setDisablePinchZoom( false );

    }

  }

  useEffect(() => { showInput && setTitleWidth( smartWidth( inputRef.current.scrollWidth, title, 16 ) * apx1 ); }, [ showInput, title ])

  return (

    <div 

      className = "table__title"
      style = {{ backgroundColor: background }}      

    >

      <TableIcon className = {`dragTable ${ cssIf( !canEdit, "events-none" ) }`} />

      { !showInput
      
          ? <span onClick = { () => ( !showInput && canEdit ) && startRenaming() } >{ title }</span>

          : <React.Fragment>
            
              <input 

                ref = { inputRef }
                type = "text" 
                value = { title } 
                onChange = { e => setTitle( e.target.value, background ) }
                onKeyUp = { handleKeyUp }
                style = {{ width: `${ titleWidth }vw` }}

              />

              { tablesLength <= 3 && 
              
                <div className = "tip_rename flex justify-center items-center absolute">
                  
                  изменяйте название таблицы прямо здесь

                  <ArrowDownIcon className = "tip_rename__arrow relative" />

                </div>
                
              }

              <ColorPicker

                style = {{ opacity: showInput ? 1 : 0 }}
                current = { background }
                set = { setBackground }
                
              />

              <ConfirmIcon 
                className = "saveNameAndColor"
                onClick = {() => {

                  if ( !canEdit ) { return; }

                  setShowInput( false );
                  setDisablePinchZoom( false );
                  set( title, background );
                  
                }}
              />

            </React.Fragment>
        
      }

      { ( !showInput && canEdit ) &&

        <TrashIcon 

          title = "Удалить таблицу"
          className = "removeTable"
          onClick = { removeTable }          

        />

      }

    </div>

  );

}

export default Table;