const FOREIGN_ON_ACTIONS = [
  {
    id: 1,
    name: `CASCADE`,
    tip: `Автоматически удаляет или изменяет строки из зависимой таблицы при удалении или изменении связанных строк в главной таблице.`
  },
  {
    id: 2,
    name: `SET NULL`,
    tip: `При удалении или обновлении связанной строки из главной таблицы устанавливает для столбца внешнего ключа значение NULL`
  },
  {
    id: 3,
    name: `RESTRICT`,
    tip: `Отклоняет удаление или изменение строк в главной таблице при наличии связанных строк в зависимой таблице.`
  },
  {
    id: 4,
    name: `SET DEFAULT`,
    tip: `При удалении связанной строки из главной таблицы устанавливает для столбца внешнего ключа значение по умолчанию, которое задается с помощью атрибуты DEFAULT. Внимание: несмотря на то, что данная опция в принципе доступна, движок InnoDB не поддерживает данное выражение.`
  },
];

export {
  FOREIGN_ON_ACTIONS
}