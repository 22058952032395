import { ReactComponent as AddIcon } from './anchor.svg';
import "./button-big-add.scss";

const ButtonBigAdd = ({ text, action }) => (

  <div className="button_big_add relative" onClick = { action }>

    <div className="button_big_add__text">{ text }</div>
    <AddIcon />
  
  </div>

);

export default ButtonBigAdd;