import { apx1 } from '../../../scripts/helpers/apx';
import { DATA_TYPES } from '../../../settings/data.types';
import { ReactComponent as TrashIcon } from './icons/trash.svg';
import { ReactComponent as KeyIcon } from './icons/key.svg';
import { ReactComponent as IndexIcon } from './icons/index.svg';
import cssIf from '../../../scripts/helpers/class.add.if';

const Row = ( props ) => {

  const { 

    id, 
    table, 
    name, 
    type, 
    comment = "", 
    foreign = false, 
    onDelete = false, 
    onUpdate = false, 
    moveY = false, 
    size, 
    decimal, 
    isNotNull, 
    isUnique, 
    isPrimaryKey, 
    autoIncrement, 
    isIndex = false,
    indexType = false,
    indexMethod = false,
    defaultValue, 
    showComments, 
    edit, 
    remove, 
    canEdit = true,
    typeColumnWidth = `${ apx1 * 135 }vw`

  } = props;

  function handleEdit() {

    edit({ 

      id, 
      name, 
      type, 
      comment, 
      foreign, 
      onDelete, 
      onUpdate, 
      size, 
      decimal, 
      isNotNull, 
      isUnique, 
      isPrimaryKey, 
      autoIncrement, 
      defaultValue,
      isIndex,
      indexType,
      indexMethod

    });

  }
    
  return (

    <div 
    
      id = { `row-${ table }-${ id }` }
      className = "table__row"
      onClick = { handleEdit }
      style = {{ gridTemplateColumns: `1fr ${ typeColumnWidth }` }}
      title = { comment.length > 0 ? comment : null }
      
    >

      { ( name !== "id" && moveY ) && moveY }
        
      <div className = "table__col table__col__name">
        
        {(( isIndex || isUnique ) && ( !isPrimaryKey && !foreign )) && 
        
          <IndexIcon className = "table__col__name__icon" title = "Индекс" />
          
        }

        {( isPrimaryKey || foreign ) && 
        
          <KeyIcon 
          
            className = {`table__col__name__icon ${ cssIf( isPrimaryKey, `primary` ) }`} 
            title = {`${ isPrimaryKey ? "Первичный" : "Вторичный" } ключ`}
            
          /> 
          
        }
                
        { name }
        
      </div>

      <div className = "table__col table__col__type">
        
        { DATA_TYPES?.find( t => t.id === type )?.name }
        
      </div>

      { showComments && <div className="table__col table__col__comment">{ comment }</div> }
      
      { canEdit &&
      
        <TrashIcon

          title = "Удалить столбец"
          className = "table__row__btn table__row__btn-remove"
          onClick = { ( e ) => { 

            remove( id ); 
            e.stopPropagation(); 
            e.preventDefault(); 

          }}

        />
      
      }

    </div>

  );

}

export default Row;