import React, { useEffect }  from "react";
import cookie from "react-cookies";
import Container from "../../components/container";
import Button from "../../components/button";
import useGlobal from "../../../store";
import { ReactComponent as Check } from "./icons/add-table-col.svg";
import { TARIFF } from "../../../settings/constants";
import { useNavigate } from "react-router-dom";
import './tariff.scss';

const Tariff = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { sign } = globalActions;
  const navigate = useNavigate();
	const token = cookie.load('token');

	useEffect(() => { 

		if ( !token ) { sign.Out(); }
      
    sign.Check( token, false, navigate );
  
  }, []);
    
  return (

    <Container>

      <div className = "sign">

        <div className = "sign__content tariff">

          <div>

            <p className = "tariff__title">Используй Database Design на максимум!</p>
            <p className = "tariff__second_title">Поддерживая разработчика, и внося вклад в развитие проекта</p>

            <div className = "tariff__list__container">

              <div className = "tariff__list__container__content">

                <Check />
                <p>Приятный интуитивный интерфейс конструктора схем баз данных</p>

              </div>

              <div className = "tariff__list__container__content">

                <Check />
                <p>Самый наглядный просмотр визуальной схемы БД из аналогов</p>

              </div>

              <div className = "tariff__list__container__content">

                <Check />
                <p>Делись визуальными схемами баз данных с командой и заказчиками</p>

              </div>

              <div className = "tariff__list__container__content">

                <Check />
                <p>Экспортируй визуальные схемы в SQL-дампы (со связями и индексами)</p>

              </div>

              <div className = "tariff__list__container__content">

                <Check />
                <p>Создавай неограниченное количество проектов</p>

              </div>

              <div className = "tariff__list__container__content">

                <Check />
                <p>Для работы требуется доступ в интернет и компьютер/ноутбук</p>

              </div>

              <div className = "tariff__list__container__content">

                <Check />
                <p>По окончанию подписки никаких авто-платежей не будет</p>

              </div>

            </div>

            <div className = "tariff__respect flex justify-center items-center">

              <div className = "tariff__respect__icon tariff__respect__icon--ava" />
              Поддержи проект!
              <div className = "tariff__respect__icon tariff__respect__icon--smile" />
              Закинь автору респект
              <div className = "tariff__respect__icon tariff__respect__icon--money" />

            </div>

            <div className = "tariff__buttons__container">

              <div className = "tariff__buttons__container__content tariff__buttons__container__content_left">

                <p className = "tariff__buttons__container__content__text tariff__buttons__container__content__text-left">Стоимость: 299р в месяц</p>
                <p className = "tariff__buttons__container__content__subtext"><span>Это всего <b>10</b> рублей в день!</span></p>
                
                <Button action = {() => { globalActions.tariff.pay( TARIFF.MONTH ) }}>Оплатить 1 месяц: 299р</Button>

              </div>

              <div className = "tariff__buttons__container__content tariff__buttons__container__content_right">

                <p className = "tariff__buttons__container__content__text tariff__buttons__container__content__text-right">Всего 180р в месяц при оплате за год!</p>
                <p className = "tariff__buttons__container__content__subtext">🔥 <span>Это всего <b>6</b> рублей в день!</span> 🔥</p>

                <Button action = {() => { globalActions.tariff.pay( TARIFF.YEAR ) }}>Оплатить за год: 2160р</Button>

              </div>

            </div>

            <p className = "tariff__subtitle">Твои проекты баз данных ещё никогда не выглядели так качественно!</p>

          </div>
            
        </div>

      </div>

    </Container>

  );

}

export default Tariff;