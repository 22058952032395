import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import useGlobal from '../../../store';
import cookie from 'react-cookies';
import './payment-result.scss';

import Textfield from "../../components/textfield";
import Container from "../../components/container";
import Button, { ButtonRouter } from "../../components/button";
import { ReactComponent as DB } from "./db.svg";

const PaymentResult = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { user_tariff } = globalState;
  const navigate = useNavigate();
  const [ isSuccess, setIsSuccess ] = useState( "loading" );

  useEffect(() => {

    if ( window.location.search.indexOf('success') !== -1 ) {
      setIsSuccess( true );
    } else  if ( window.location.search.indexOf('fail') !== -1 ) {
      setIsSuccess( false );
      //TODO запрос на смену статуса платежа canceled
    
    } else {
      window.location = "/";
    }

  }, [ ]);

  return (

    <Container>

      <div className="sign">

        { isSuccess === "loading" ? <div className="sign__content payment-result"></div>
          
          : isSuccess 
          
          ? <div className="sign__content payment-result">

              <p className="payment-result__title">Успешно оплачено!</p>
              <p className="payment-result__subtitle">Ваш тариф будет действовать до { user_tariff?.next_date }</p>

              <DB />

              <p className="payment-result__thanking">Приятного использования!</p>

              <Button action = {() => { window.location = '/' }}>Начать использование</Button>

            </div>
          
          : <div className="sign__content payment-result payment-result--fail">

              <p 
                className="payment-result__title" 
                style={{
                  paddingBottom: '0',
                  marginBottom: '1.8vw'
                }}
              >
                Платёж отменён
              </p>
              <p className="payment-result__thanking" style={{marginTop: '0'}}>Вы прервали оплату тарифа</p>
              <p className="payment-result__subtitle">Связь с техподдержкой: +7 999 157 30 44</p>

              <DB />

              <p className="payment-result__thanking">Попробуйте ещё раз!</p>

              <Button
                    
                action = {() => { navigate('/tariff'); }}
                children = "Вернуться к тарифам"

              />

            </div>
            
          }

      </div>

    </Container>

  );

}


export default PaymentResult;