import React from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import { ReactComponent as Checked } from './checked.svg';
import "./checkbox.scss";

const Checkbox = ({ value = false, set, title = "", className = "" }) => (

  <div

    className = {`checkbox ${ cssIf( value, 'checkbox--active' ) } ${ className } flex items-center`}
    onClick = { () => { set( !value ) } }
    
  >

    <div className = "checkbox__anchor flex justify-center items-center">
      <Checked />
    </div>
    
    { title !== "" && <div className = "checkbox__title">{ title }</div> }

  </div>

);

export default Checkbox;