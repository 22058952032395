import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ReactComponent as MoveIcon } from './icons/arrow.move.row.svg';
import Row from './row';

const SortableRows = SortableContainer(({ table_id, data, typeColumnWidth, showComments, openEditForm, removeColumn, canEdit = true }) => (

  <div>
    
    { data?.length > 0 && data.map(( col, index ) => (

      <SortableRow

        key = { `item-${index}` }
        index = { index }
        sortIndex = { index }
        canEdit = { canEdit }
        typeColumnWidth = { typeColumnWidth }
        row = {{

          table: table_id,
          id: col?.id,
          name: col?.name,
          type: col?.type,
          size: col?.size,
          decimal: col?.decimal,
          isNotNull: col?.isNotNull,
          isUnique: col?.isUnique,
          isPrimaryKey: col?.isPrimaryKey,
          autoIncrement: col?.autoIncrement,
          defaultValue: col?.defaultValue,
          comment: col?.comment,
          foreign: col?.foreign,
          onDelete: col?.onDelete,
          onUpdate: col?.onUpdate,
          isIndex: col?.isIndex,
          indexType: col?.indexType,
          indexMethod: col?.indexMethod,
          showComments: showComments,
          edit: openEditForm,
          remove: ( col_id ) => removeColumn( table_id, col_id )

        }}

      />

    ))}
  
  </div>

));

const DragHandler = SortableHandle(() => (

  <MoveIcon

    className = "table__row__btn table__row__btn-move"
    onClick = { ( e ) => { 

      e.stopPropagation(); 
      e.preventDefault(); 

    }}
    
  />
  
));

const SortableRow = SortableElement(({ row, sortIndex, canEdit, typeColumnWidth }) => (

  <Row

    key = { sortIndex }
    moveY = { canEdit && <DragHandler /> }
    canEdit = { canEdit }
    typeColumnWidth = { typeColumnWidth }
    { ...row }

  />

));

export default SortableRows;