const DT_GROUP = {

  NUMBER:   1,
  DECIMAL:  2,
  TEXT:     3,
  BINARY:   4,
  DATETIME: 5,
  CUSTOM:   6,
  SPATIAL:  7,
  OTHER:    8

}

const DTG = DT_GROUP;

const DATA_TYPES = [

  { id:   0, group: DTG.NUMBER,   name: "integer" },
  { id:  15, group: DTG.NUMBER,   name: "tinyint" },
  { id:  16, group: DTG.NUMBER,   name: "smallint" },
  { id:  17, group: DTG.NUMBER,   name: "mediumint" },
  { id:  18, group: DTG.NUMBER,   name: "bigint" },
  
  { id:  10, group: DTG.DECIMAL,  name: "float" },
  { id:  19, group: DTG.DECIMAL,  name: "double" },
  { id:  20, group: DTG.DECIMAL,  name: "decimal" },

  { id:   1, group: DTG.TEXT,     name: "varchar" },
  { id:   2, group: DTG.TEXT,     name: "text" },
  { id:  21, group: DTG.TEXT,     name: "char" },
  { id:   6, group: DTG.TEXT,     name: "tinytext" },
  { id:   7, group: DTG.TEXT,     name: "mediumtext" },
  { id:   8, group: DTG.TEXT,     name: "longtext" },

  { id:  28, group: DTG.BINARY,   name: "binary" },
  { id:  29, group: DTG.BINARY,   name: "varbinary" },
  { id:  12, group: DTG.BINARY,   name: "blob" },
  { id:  11, group: DTG.BINARY,   name: "tinyblob" },
  { id:  13, group: DTG.BINARY,   name: "mediumblob" },
  { id:  14, group: DTG.BINARY,   name: "longblob" },

  { id:  22, group: DTG.DATETIME, name: "date" },
  { id:   3, group: DTG.DATETIME, name: "datetime" },
  { id:   4, group: DTG.DATETIME, name: "timestamp" },
  { id:  23, group: DTG.DATETIME, name: "time" },
  { id:  24, group: DTG.DATETIME, name: "year" },

  { id:  25, group: DTG.CUSTOM,   name: "enum" },
  { id:  26, group: DTG.CUSTOM,   name: "set" },

  { id:  30, group: DTG.SPATIAL,  name: "geometry" },
  { id:  31, group: DTG.SPATIAL,  name: "point" },
  { id:  32, group: DTG.SPATIAL,  name: "linestring" },
  { id:  33, group: DTG.SPATIAL,  name: "polygon" },
  { id:  35, group: DTG.SPATIAL,  name: "multipoint" },
  { id:  37, group: DTG.SPATIAL,  name: "multipolygon" },
  { id:  36, group: DTG.SPATIAL,  name: "multilinestring" },
  { id:  34, group: DTG.SPATIAL,  name: "geometrycollection" },

  { id:   5, group: DTG.OTHER,    name: "boolean" },
  { id:   9, group: DTG.OTHER,    name: "json" },
  { id:  27, group: DTG.OTHER,    name: "uuid" },

];

const DATA_TYPES_TIPS = [

  /* integer            */ { id:   0, tip: `Хранит целое число, без дробной части в диапазоне от -2.147.483,648 до 2.147.483.647, или беззнаковые числа от 0 до 4.294.967.295` },
  /* tinyint            */ { id:  15, tip: `Хранит целое число, без дробной части в диапазоне от -128 до 127, или беззнаковые числа от 0 до 255.` },
  /* smallint           */ { id:  16, tip: `Хранит целое число, без дробной части в диапазоне от -32.768 до 32.767, или беззнаковые числа от 0 до 65,535` },
  /* mediumint          */ { id:  17, tip: `Хранит целое число, без дробной части в диапазоне от -8.388.608 до 8.388.607, или беззнаковые числа от 0 до 16.777.215` },
  /* bigint             */ { id:  18, tip: `Хранит целое число, без дробной части в диапазоне от -9.223.372.036.854.775.808 до 9.223.372.036.854.775.807, или беззнаковые числа от 0 до 18.446.744.073.709.551.615` },
  
  /* float              */ { id:  10, tip: `Хранит число с плавающей запятой в диапазоне от -3.402823466E+38 до -1.175494351E-38, 0 и от 1.175494351E-38 до 3.402823466E+38. Точность чисел в типе FLOAT может меняться в зависимости от их масштаба. Обычно, точность FLOAT составляет около 7 цифр. Однако, стоит помнить, что тип данных FLOAT представляет приближенное представление чисел с плавающей запятой и может иметь потерю точности. Если точность критически важна для ваших данных, может быть лучше использовать тип данных DECIMAL, который обеспечивает точное хранение чисел с фиксированной точностью и масштабом. Важно знать, что превышение диапазона чисел в FLOAT может привести к ошибкам округления, чтобы избежать этого рекомендуется тщательно рассчитывать необходимые пределы и проверять данные перед их сохранением в поле типа FLOAT.` },
  /* double             */ { id:  19, tip: `Хранит число с плавающей запятой в диапазоне от -1.7976931348623157E+308 до -2.2250738585072014E-308, 0 и от 2.2250738585072014E-308 до 1.7976931348623157E+308. Тип данных DOUBLE является приближенным представлением чисел с плавающей запятой и может иметь потерю точности в некоторых случаях. Поэтому, для высокой точности без потери данных может быть лучше использовать тип данных DECIMAL, который обеспечивает точное хранение чисел с фиксированной точностью и масштабом.` },
  /* decimal            */ { id:  20, tip: `Хранит число (в т.ч. дробное), с фиксированной точностью и масштабом. Представляет числа в формате десятичной дроби, где точность определяется количеством цифр в числе, а масштаб - количеством знаков после запятой. Тип данных DECIMAL в отличие от типов FLOAT и DOUBLE предоставляет хранение чисел без потери точности. Стоит учитывать, что тип данных DECIMAL требует больше памяти для хранения данных по сравнению с типами FLOAT и DOUBLE. Кроме того, при выполнении математических операций над числами типа DECIMAL необходимо обращать внимание на возможное переполнение и необходимость округления результатов.` },

  /* varchar            */ { id:   1, tip: `Строка переменной длины до 65.535 символов. Причем в памяти хранится именно та длина, которая была указана при создании. VARCHAR занимает меньше места, чем CHAR, но подвержен фрагментации и из-за этого может проигрывать в скорости обработки данных. Поле типа varchar может быть использовано для создания индексов, включая индекс по первичному ключу. С text индексирование ограничено, и некоторые операции с индексированными столбцами text могут быть ограничены или недоступны.` },
  /* text               */ { id:   2, tip: `Строка переменной длины до 65.535 символов. Обрабатывается как символьная строка. В ней хранятся именно набор символов, а значения сортируются и сравниваются на основе сопоставления набора символов. В отличие от varchar, text хранит данные в отдельном блоке, а не в самой строке таблицы, что может повлиять на производительность при выполнении операций чтения и записи.` },
  /* char               */ { id:  21, tip: `Строка фиксированной длины до 255 символов. Если длина вставляемой записи меньше, то MySQL автоматически дополняет значение пробелами` },
  /* tinytext           */ { id:   6, tip: `Строка переменной длины до 255 символов. Обрабатывается как символьная строка. В ней хранятся именно набор символов, а значения сортируются и сравниваются на основе сопоставления набора символов. В отличие от varchar, text хранит данные в отдельном блоке, а не в самой строке таблицы, что может повлиять на производительность при выполнении операций чтения и записи.` },
  /* mediumtext         */ { id:   7, tip: `Строка переменной длины до 16.777.215 символов. Обрабатывается как символьная строка. В ней хранятся именно набор символов, а значения сортируются и сравниваются на основе сопоставления набора символов. В отличие от varchar, text хранит данные в отдельном блоке, а не в самой строке таблицы, что может повлиять на производительность при выполнении операций чтения и записи.` },
  /* longtext           */ { id:   8, tip: `Строка переменной длины до 4.294.967.295 символов. Обрабатывается как символьная строка. В ней хранятся именно набор символов, а значения сортируются и сравниваются на основе сопоставления набора символов. В отличие от varchar, text хранит данные в отдельном блоке, а не в самой строке таблицы, что может повлиять на производительность при выполнении операций чтения и записи.` },
  
  /* binary             */ { id:  28, tip: `Двочиные данные фиксированной длины.` },
  /* varbinary          */ { id:  29, tip: `Двочиные данные переменной длины.` },
  /* blob               */ { id:  12, tip: `Двоичные данные до 65.535 байт (64 Kb). Может хранить любые файлы или мультимедиа-контент. Операции сортировки и сравнения основаны на числовых значениях байтов.` },
  /* tinyblob           */ { id:  11, tip: `Двоичные данные до 255 байт. Может хранить любые файлы или мультимедиа-контент. Операции сортировки и сравнения основаны на числовых значениях байтов.` },
  /* mediumblob         */ { id:  13, tip: `Двоичные данные до 16.777.215 байт (16 Mb). Может хранить любые файлы или мультимедиа-контент. Операции сортировки и сравнения основаны на числовых значениях байтов.` },
  /* longblob           */ { id:  14, tip: `Двоичные данные до 4.294.967.295 байт (4 Gb). Может хранить любые файлы или мультимедиа-контент. Операции сортировки и сравнения основаны на числовых значениях байтов.` },

  /* date               */ { id:  22, tip: `Только дата в формате YYYY-MM-DD. Допустимые значения от 1000-01-01 до 9999-12-31` },
  /* datetime           */ { id:   3, tip: `Дата и время в формате YYYY-MM-DD HH:MM:SS. Допустимые значения от 1000-01-01 00:00:00 до 9999-12-31 23:59:59` },
  /* timestamp          */ { id:   4, tip: `Дата и время. Хранится в виде количества секунд, прошедших с 1 января 1970 года по гринвичу. Занимает в два раза меньше места, чем тип DATETIME. Но при этом диапазон ограничен значениями от 1970-01-01 00:00:01 до 2038-01-09 03:14:07` },
  /* time               */ { id:  23, tip: `Только время в формате HH:MM:SS. Допустимые значения от 00:00:00 до 23:59:59` },
  /* year               */ { id:  24, tip: `Только год в формате YYYY или YY. Допустимые значения от 1901 до 2155 или от 70 до 69 (1970 — 2069)` },

  /* enum               */ { id:  25, tip: `Хранит только одно значение из установленного списка допустимых значений, занимает 1-2 байта.` },
  /* set                */ { id:  26, tip: `Хранит одновременно до 64 значений из установленного списка допустимых значений, занимает от 1 до 8 байт.` },

  /* geometry           */ { id:  30, tip: `Хранит значения геометрии любого типа. Другие типы с одиночным значением (POINT, LINESTRING и POLYGON) ограничивают их значения специфическим типом геометрии.` },
  /* point              */ { id:  31, tip: `Используется для хранения двухмерных географических координат - широты и долготы.` },
  /* linestring         */ { id:  32, tip: `Используется для хранения геометрии линии, состоящей из последовательности точек. Представляет собой набор упорядоченных точек, соединенных прямыми отрезками.` },
  /* polygon            */ { id:  33, tip: `Используется для хранения и работы с географическими полигонами. Полигон представляет собой закрытую фигуру на плоскости, ограниченную линиями соединения вершин. Данный тип данных полезен, когда вам нужно хранить информацию о географических объектах, таких как границы территорий, места интереса или формы земельных участков.` },
  /* multipoint         */ { id:  35, tip: `Хранит совокупность объектов двухмерных географических координат - широты и долготы.` },
  /* multipolygon       */ { id:  37, tip: `Хранит совокупность объектов географических полигонов. Полигон представляет собой закрытую фигуру на плоскости, ограниченную линиями соединения вершин. Данный тип данных полезен, когда вам нужно хранить информацию о географических объектах, таких как границы территорий, места интереса или формы земельных участков.` },
  /* multilinestring    */ { id:  36, tip: `Хранит совокупность объектов геометрии линии, состоящей из последовательности точек. Представляет собой набор упорядоченных точек, соединенных прямыми отрезками.` },
  /* geometrycollection */ { id:  34, tip: `Хранит совокупность объектов любого типа. Другие типы совокупности (MULTIPOINT, MULTILINESTRING, MULTIPOLYGON и GEOMETRYCOLLECTION) ограничивают элементы совокупности имеющими специфический тип геометрии.` },

  /* boolean            */ { id:   5, tip: `Хранит Булево значение "истина" или "ложь" (true/false)` },
  /* json               */ { id:   9, tip: `Хранит данные в формате JSON` },
  /* uuid               */ { id:  27, tip: `Тип данных UUID обеспечивает уникальность идентификаторов даже в условиях распределенной системы и позволяет избежать конфликтов идентификаторов при интеграции нескольких источников данных или при работе с параллельными процессами. Обратите внимание, что в MySQL нет встроенного типа данных UUID (в отличии от например PostgreSQL и MongoDB), но uuid можно сгенерировать на стороне бэкенда и хранить в виде VARCHAR или ИШТФКН` },
  
];

function assignTipsForTypes( withoutTipsTypes ) {

  const result = []

  withoutTipsTypes.forEach( type => {
    
    let assigned = type;

    const finded = DATA_TYPES_TIPS.find( t => +t.id === +type.id );
    if ( finded ) { assigned.tip = finded.tip; }

    result.push( assigned );

  });

  return result;

}

const DATA_TYPES_GROUPS = [
  { 
    id: [ DT_GROUP.NUMBER ],
    name: "Числовые",
    types: assignTipsForTypes( DATA_TYPES.filter( type => type.group === DT_GROUP.NUMBER ) ),
    tip: ``
  },
  { 
    id: [ DT_GROUP.DECIMAL ],
    name: "Дробные",
    types: assignTipsForTypes( DATA_TYPES.filter( type => type.group === DT_GROUP.DECIMAL ) ),
    tip: ``
  },
  { 
    id: [ DT_GROUP.TEXT ],
    name: "Текстовые",
    types: assignTipsForTypes( DATA_TYPES.filter( type => type.group === DT_GROUP.TEXT ) ),
    tip: ``
  },
  { 
    id: [ DT_GROUP.BINARY ],
    name: "Бинарные",
    types: assignTipsForTypes( DATA_TYPES.filter( type => type.group === DT_GROUP.BINARY ) ),
    tip: ``
  },
  { 
    id: [ DT_GROUP.DATETIME ],
    name: "Дата/время",
    types: assignTipsForTypes( DATA_TYPES.filter( type => type.group === DT_GROUP.DATETIME ) ),
    tip: `Типы данных, которые позволяют работать с датой и временем.`
  },
  { 
    id: [ DT_GROUP.CUSTOM ],
    name: "Составные",
    types: assignTipsForTypes( DATA_TYPES.filter( type => type.group === DT_GROUP.CUSTOM ) ),
    tip: `Строковые типы данных, которые могут хранить значения только из заранее определенного списка. Несмотря на то, что список значений состоит из строк, в самих таблицах хранятся только числа, которые ассоциированы со справочником возможных значений. Поэтому они занимают мало места.`
  },
  { 
    id: [ DT_GROUP.SPATIAL ],
    name: "Геометрические",
    types: assignTipsForTypes( DATA_TYPES.filter( type => type.group === DT_GROUP.SPATIAL ) ),
    tip: ``
  },
  { 
    id: [ DT_GROUP.OTHER ],
    name: "Прочие",
    types: assignTipsForTypes( DATA_TYPES.filter( type => type.group === DT_GROUP.OTHER ) )
  },

];

export {

  DATA_TYPES,
  DATA_TYPES_GROUPS,
  DT_GROUP

}