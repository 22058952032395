
import axios from "axios";
import API from "../../settings/api";
import cookie from "react-cookies";

export async function changeMode( store, { scheme_id, mode }) {

  try {

    store.setState({ scheme_shared_mode: mode });

    const token = cookie.load('token');
    if ( !token ) { console.error(`Попытка разлогина из share.changeMode`); return false; }

    const params = { token, scheme_id, mode }
    const { status, data } = await axios.post( API.scheme.share.changeMode, params );

    console.log(`share.changeMode data: ${status}`, data);

    if ( !data.success ) { throw( data.error ); }

    return true;

  } catch ( err ) {

    console.error(`Попытка разлогина из share.changeMode:`, err);
    store.actions.sign.Out();
    return false;    

  }
  
}

export async function addEmail( store, { scheme_id, email, access }) {

  try {

    const token = cookie.load('token');
    if ( !token ) { console.error(`Попытка разлогина из share.addEmail`); return false; }
    
    let params = { token, scheme_id, email, access }
    const { status, data } = await axios.post( API.scheme.share.addEmail, params );

    console.log(`share.addEmail data: ${status}`, data);

    if ( !data.success ) { throw( data.error ); }

    params.id = data.relation_id;

    const updated = store.state.scheme_shared_emails;

    updated.push({

      id: data.relation_id,
      email,
      access

    })

    store.setState({ scheme_shared_emails: updated });

    setTimeout(() => {

      store.actions.updateRender();

    }, 200 );

    return true;

  } catch ( err ) {

    console.error(`share.addEmail:`, err);
    alert( err );
    return false;    

  }
  
}

export async function changeAccessForEmail( store, { scheme_id, share_id, access }) {

  try {

    const token = cookie.load('token');
    if ( !token ) { console.error(`Попытка разлогина из all scheme`); return false; }
    
    const params = { 

      token,
      access,
      scheme_id,
      share_id
    
    }
    
    const { status, data } = await axios.post( API.scheme.share.changeAccess, params );

    console.log(`share.changeAccessForEmail data: ${status}`, data);

    if ( !data.success ) { throw( data.error ); }

    let shared = store.state.scheme_shared_emails;
    let user = shared.find( el => el.id === scheme_id );
    user.access = access;

    store.setState({ scheme_shared_emails: shared });

    return true;

  } catch ( err ) {

    console.error(`share.changeAccessForEmail:`, err);
    // store.actions.sign.Out();
    return false;    

  }
  
}

export async function removeEmail( store, { share_id, scheme_id }) {

  try {

    const token = cookie.load('token');
    if ( !token ) { console.error(`Попытка разлогина из all scheme`); return false; }
    
    const params = { token, share_id, scheme_id }
    
    const { status, data } = await axios.post( API.scheme.share.removeEmail, params );

    console.log(`share.removeEmail data: ${status}`, data);

    if ( !data.success ) { throw( data.error ); }

    let shared = store.state.scheme_shared_emails.filter( el => el.id !== share_id );
    store.setState({ scheme_shared_emails: shared });

    setTimeout(() => {

      store.actions.updateRender();

    }, 200 );

    return true;

  } catch ( err ) {

    console.error(`share.removeEmail:`, err);
    alert( err );
    return false;    

  }
  
}