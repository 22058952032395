// constants used for typescript and proptypes definitions
import React from 'react';

export const cAnchorEdge = ['middle', 'left', 'right', 'top', 'bottom', 'auto'];
export const cPaths = ['smooth', 'grid', 'straight'];
export const cSvgElems = ['circle', 'ellipse', 'line', 'path', 'polygon', 'polyline', 'rect'];

//default arrows svgs
export const arrowShapes = {
  arrow1: { svgElem: <path d="M 0 0 L 1 0.5 L 0 1 L 0.25 0.5 z" />, offsetForward: 0.25 },
  heart: {
    svgElem: (
      <path d="M 0,0.25 A 0.125,0.125 0,0,1 0.5,0.25 A 0.125,0.125 0,0,1 1,0.25 Q 1,0.625 0.5,1 Q 0,0.625 0,0.25 z" />
    ),
    offsetForward: 0.1,
  },
  circle: {
    svgElem: <circle r={0.5} cx={0.5} cy={0.5} />,
    offsetForward: 0,
  },
};

export const cArrowShapes = Object.keys(arrowShapes);
