import { Fragment, useEffect, useState } from "react";
import useGlobal from "../../../../../store";
import RelationTableSelector from "./relation.table.selector";
import RelationColumnSelector from "./relation.column.selector";
import { Dummy } from "../../../../../scripts/hooks/use.reload.dummy";
import { DATA_TYPES } from "../../../../../settings/data.types";

const RelationSelector = ( props ) => {

  const {

    foreign,
    setForeign,
    setType,
    close,
    setDisablePinchZoom

  } = props;

  const [ globalState, globalActions ] = useGlobal();

  const { tables, dummy } = globalState;
  const { updateRender } = globalActions;
  
  const [ selectedTableID, setSelectedTableID ] = useState( +foreign?.id?.split('-')?.[ 0 ] );
  const [ selectedTableName, setSelectedTableName ] = useState( "" );

  const [ selectedTableColumnID, setSelectedTableColumnID ] = useState( +foreign?.id?.split('-')?.[ 1 ] );

  const [ selectedTableColumnsList, setSelectedTableColumnsList ] = useState([]);

  const [ closeAfterSelect, setCloseAfterSelect ] = useState( false );

  function resetState() {

    setSelectedTableID( null );
    setSelectedTableName( "" );
    setSelectedTableColumnsList([]);
    setSelectedTableColumnID( null );

  }
  
  function resetForeign() {

    resetState();
    setForeign({ id: false, name: "Не выбрано" });
    close();

  }

  function getTableNameAndColumns( table_id ) {

    const table = tables.find( el => +el.id === +table_id );
    
    if ( !table ) { 
      
      console.error(`getTableNameAndColumns не нашел таблицу с ID ${ table_id }`); 
      return { name: "Ошибка: не найдено", columns: [] }; 
    
    }

    return {

      name: table.name,
      columns: table.columns

    }

  }

  useEffect(() => {

    if ( !foreign?.id || foreign === "" ) { resetState(); return; }

    const relation_table_id = foreign?.id?.split('-')?.[ 0 ];
    const relation_table_column_id = foreign?.id?.split('-')?.[ 1 ];

    setSelectedTableID( +relation_table_id );
    
    if ( relation_table_column_id ) {

      setSelectedTableColumnID( relation_table_column_id );

    }

    updateRender();

  }, [ foreign, foreign?.id ]);

  useEffect(() => {

    if ( !selectedTableID ) { resetState(); return; }

    const t = getTableNameAndColumns( +selectedTableID );

    setSelectedTableName( t.name );
    setSelectedTableColumnsList( t.columns );

    if ( selectedTableColumnID ) {

      const column = t.columns.find( el => +el.id === +selectedTableColumnID );
      
      setForeign({
        
        id: `${ selectedTableID }-${ selectedTableColumnID }`,
        name: `${ selectedTableName }.${ column.name }`
      
      });

      if ( column?.type || column?.type?.id ) {

        const findedType = DATA_TYPES?.find( t => parseInt( t.id ) === parseInt( column.type ) );

        setType({ 
  
          id: column.type, 
          name: findedType?.name || "",
          group: findedType?.group || ""
  
        })

      }

      if ( closeAfterSelect ) {
        
        setCloseAfterSelect( false );
        close();

      }

      updateRender();

    }

  }, [ selectedTableID, selectedTableColumnID ]);

  return (

    <div
    
      onMouseEnter = { () => setDisablePinchZoom( true ) }
      onMouseLeave = { () => setDisablePinchZoom( false ) }

    >

      <Dummy random = { dummy } />

      <div onClick = { resetForeign } className = "remove_relation absolute pointer">удалить связь</div>

      { !selectedTableID
      
        ? <RelationTableSelector 
        
            tables = { tables }
            select = { setSelectedTableID }
            removeRelation  = { resetForeign }
            
          />

        : <RelationColumnSelector

            columns = { selectedTableColumnsList }
            tableID = { selectedTableID }
            tableName = { selectedTableName }
            selected = { selectedTableColumnID }

            chooseOtherTable = { resetState }
            removeRelation  = { resetForeign }

            select = { ( payload ) => { 
              
              setSelectedTableColumnID( payload ); 
              setCloseAfterSelect( true ); 
            
            }}
        
          />
          
      }
        
    </div>

  );

}

export default RelationSelector;