import React, { useRef, useEffect } from 'react';
import './textarea.scss';

const Textarea = ({ value, set, placeholder = "", title = "", className = "", refDOM = false }) => {

  const textarea = useRef();

  useEffect(() => { autoGrow(); }, [ value ]);

  function autoGrow() {

    const i = refDOM ? refDOM : textarea;

    i.current.style.height = "42px";
    i.current.style.height = i.current.scrollHeight + "px";

  }

  return (

    <div className = {`textarea ${ className }`}>

      { title !== "" && <div className="textarea__title">{ title }</div> }

      <textarea 

        ref = { refDOM ? refDOM : textarea }
        value = { value }
        onChange = {( e ) => { set( e.target.value ) }}
        placeholder = { placeholder }

      />

    </div>

  );

}

export default Textarea;