import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';
import './constructor.scss';
import Container from '../../components/container';
import useReloadDummy, { Dummy as GlobalDummy } from '../../../scripts/hooks/use.reload.dummy';
import ButtonBigAdd from '../../components/button-big-add';
import useGlobal from '../../../store';
import DynamicArrows from '../../components/arrows';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import RenderTables from '../../components/render.tables';
import PopupHelp from '../../popups/help';
import cssIf from '../../../scripts/helpers/class.add.if';
import PopupShare from '../../popups/popup.share';
import { SHARE_ACCESS, THEME } from '../../../settings/constants';
import { SENSIVITY } from '../../../settings/user.mouse.settings';
import MessageFromRikky from '../../components/message.from.rikky';
import PopupConfirm from '../../popups/popup.confirm';
import useXarrow from '../../components/dynamic.arrows/useXarrow';
import Xwrapper from '../../components/dynamic.arrows/xwrapper';

const Constructor = () => {

  const updateXarrow = useXarrow();
  const [ Dummy, reloadDummy ] = useReloadDummy();
  const [ globalState, globalActions ] = useGlobal();
  
  const { 
    
    dummy,
    theme,
    scheme_access,
    user_tariff, 
    user_controller,
    tables, 
    showHelpPopup, 
    isTablesOnTopLayer, 
    disableOpenedHelp 
  
  } = globalState;

  const { changeStates, scheme, popup } = globalActions;

  const canEdit = scheme_access === SHARE_ACCESS.EDIT;

  const { id } = useParams();
  const navigate = useNavigate();
  const [ disablePinchZoom, setDisablePinchZoom ] = useState( false );

  const initialScale = +cookie.load('dbd-scale') || 0.8;
  const [ currentScale, setCurrentScale ] = useState( initialScale );

  const popupShare = PopupShare();
  const popupConfirm = PopupConfirm();

  const setTablesOnTopLayer = async bool => changeStates({ isTablesOnTopLayer: bool })

  function refreshArea() {

    reloadDummy();
    updateXarrow();

  }
  
  useEffect(() => {
    
    !id && navigate('/'); 
    globalActions.scheme.get( id, refreshArea );

    document.body.style.overflow = 'hidden';
    document.getElementById('root').style.overflow = 'hidden';

    return () => {
      
      document.body.style.overflow = 'auto';
      document.getElementById('root').style.overflow = 'auto';
      
    }

  }, []);

  useEffect(() => { disableOpenedHelp && globalActions.sign.disableDefaultOpenedHelp(); }, [ disableOpenedHelp ])

  async function handleUpdateTablePosition( table_id, position ) {
    
    if ( !user_tariff?.active ) { navigate('/tariff'); return; }

    let t = tables;

    for ( let i = 0; i < t.length; i++ ) {

      if ( t[ i ].id === table_id ) {

        t[ i ].position = position;

      }

    }

    scheme.save( id, t );
    changeStates({ tables: t });
    refreshArea();

  }

  async function handleUpdateRowsPosition( table_id, columns ) {
    
    if ( !user_tariff?.active ) { navigate('/tariff'); return; }

    let t = tables;

    for ( let i = 0; i < t.length; i++ ) {

      if ( t[ i ].id === table_id ) {

        t[ i ].columns = columns;

      }

    }

    scheme.save( id, t );
    changeStates({ tables: t });
    reloadDummy();

  }

  async function handleAddTable() {
    
    if ( !user_tariff?.active ) { navigate('/tariff'); return; }

    let initialX = 1280;
    let initialY = 730;

    let offsetX = 300;
    let offsetY = 150;

    let positionX = initialX + ( ( tables.length % 5 ) * offsetX );
    let positionY = initialY + ( Math.floor( tables.length / 5 ) * offsetY );

    const newID = Date.now();

    const initialTable = {

      id: newID,
      name: "new table",
      color: "#34A400",
      position: {
        x: positionX,
        y: positionY
      },
      columns: [
        {
          id: 1,
          name: "id",
          type: 0,
          isPrimaryKey: true,
          isNotNull: true,
          autoIncrement: true
        }
      ]
    
    }

    const temp = tables;
    temp.push( initialTable );
    scheme.save( id, temp );
    changeStates({ tables: temp });
    setDisablePinchZoom( false );
    reloadDummy();

  }

  async function handleRenameTable( table_id, name, color ) {
    
    if ( !user_tariff?.active ) { navigate('/tariff'); return; }
    //// console.log(`handleRenameTable(${table_id},${name})`);
    let t = tables;

    for ( let i = 0; i < t.length; i++ ) {

      if ( t[ i ].id === table_id ) {

        t[ i ].name = name;
        t[ i ].color = color;

      }

    }

    scheme.save( id, t );
    changeStates({ tables: t });
    reloadDummy();

  }

  async function handleRemoveTable( table_id ) {

    if ( !user_tariff?.active ) { navigate('/tariff'); return; }
    
    const table = tables.find( tb => +tb.id === +table_id );
    const skipConfirm = table.columns.length <= 1;

    if ( skipConfirm ) {

      removeTable( table_id );
      return;

    }

    popup.confirm({
      
      text: <Fragment>Вы хотите удалить таблицу { table?.name }?<br/>Это действие необратимо!</Fragment>,
      action: () => removeTable( table_id )
      
    });

  }
  
  async function removeTable( table_id ) {

    console.log(`tables.remove table id`, table_id );
    
    let t = tables;
    const temp = [];

    for ( let i = 0; i < t.length; i++ ) {

      if ( t[ i ].id !== table_id ) {

        let cancelForeign = t[ i ].columns.find( col => col?.foreign && col.foreign.indexOf(`${ table_id }-`) !== -1 );
        if ( cancelForeign ) { cancelForeign.foreign = false; }

        temp.push( t[ i ] );

      }

    }

    console.log(`tables.after`, temp );

    changeStates({ tables: [] });
    await scheme.save( id, temp );
    changeStates({ tables: temp });
    reloadDummy();

  }

  async function handleAddColumn( table_id, data ) {

    if ( !user_tariff?.active ) { navigate('/tariff'); return; }

    console.log(`handleAddColumn(${table_id})`, data);
    let t = tables;

    for ( let i = 0; i < t.length; i++ ) {

      if ( t[ i ].id === table_id ) {

        let c = t[ i ].columns;

        const newID = Date.now();

        c.push({ 

          ...data,
          id: newID

        });

      }

    }

    scheme.save( id, t );
    changeStates({ tables: t });
    reloadDummy();

  }

  async function handleEditColumn( table_id, column_id, data ) {

    if ( !user_tariff?.active ) { navigate('/tariff'); return; }

    let t = tables;

    for ( let i = 0; i < t.length; i++ ) {

      if ( parseInt( t[ i ].id ) === parseInt( table_id ) ) {

        let c = t[ i ].columns;

        for ( let j = 0; j < c.length; j++ ) {

          if ( parseInt( c[ j ].id ) === parseInt( column_id ) ) {

            c[ j ] = data;

          }

        }

        t[ i ].columns = c;

      }

    }

    scheme.save( id, t );
    changeStates({ tables: t });
    reloadDummy();

  }

  async function handleRemoveColumn( table_id, column_id ) {

    if ( !user_tariff?.active ) { navigate('/tariff'); return; }

    const table = tables.find( tb => +tb.id === +table_id );
    const column = table.columns.find( tc => +tc.id === +column_id );

    popup.confirm({

      text: <Fragment>Вы хотите удалить столбец { column?.name } из таблицы { table?.name }?<br/>Это действие необратимо!</Fragment>,
      action: () => removeColumn( table_id, column_id )

    });

  }

  async function removeColumn( table_id, column_id ) {

    let t = tables;

    for ( let i = 0; i < t.length; i++ ) {

      if ( t[ i ].id === table_id ) {

        let c = [];

        for ( let j = 0; j < t[ i ].columns.length; j++ ) {

          t[ i ].columns[ j ].id !== column_id && c.push( t[ i ].columns[ j ] );

        }   
        
        t[ i ].columns = c;

      } else {

        let cancelForeign = t[ i ].columns.find( col => col?.foreign === `${ table_id }-${ column_id }` );
        if ( cancelForeign ) { cancelForeign.foreign = false; }

      }

    }

    scheme.save( id, t );
    changeStates({ tables: t });
    updateXarrow();
    reloadDummy();

  }

  const TransformWrapperIgnoreClasses = ["input", "textarea"]

  function saveScaleToCookies( scl ) { 
    
    setCurrentScale( scl );
    cookie.save('dbd-scale', scl, { path: '/' } ); 
  
  }

  return (

    <Fragment>

      { !showHelpPopup ? "" :
      
        <PopupHelp close = { () => { changeStates({ showHelpPopup: false }) }} />
        
      }

      <Container 
      
        className = {`container__main container--constructor ${ cssIf( theme === THEME.LIGHT, `container__main--light` ) }`}
        popups = {(
          <Fragment>
            { popupConfirm.initialize }
            { popupShare.initialize }
          </Fragment>
        )}
      
      >

        <div 
          id = "workspace"
          className = {`constructor ${ cssIf( isTablesOnTopLayer, 'constructor--tables-on-top-layer') }`} 
        >
          
          <Dummy />
          <Dummy random = { dummy } />
          
          <Xwrapper>
              
            { tables.length > 0 && tables.map(( table, key ) => (
              
              <DynamicArrows

                key = { key }
                table_id = { table.id } 
                data = { table.columns } 
                zoom = { currentScale }
                isTablesOnTopLayer = { isTablesOnTopLayer }

              />

            ))}

            <TransformWrapper
              //// initialPositionX={200}
              //// initialPositionY={100}
              centerOnInit = { true }
              initialScale={ initialScale }
              minScale={ 0.15 }
              
              doubleClick = {{ disabled: true }}
              zoomAnimation = {{ disabled: true }}
              alignmentAnimation = {{ disabled: true }}
              velocityAnimation = {{ disabled: true }}
              wheel = {{ 
                disabled: disablePinchZoom,
                excluded: TransformWrapperIgnoreClasses,
                step: SENSIVITY[ user_controller ]
              }}
              pinch = {{ 
                disabled: disablePinchZoom,
                excluded: TransformWrapperIgnoreClasses 
              }}
              panning = {{
                disabled: disablePinchZoom,
                velocityDisabled: true,
                excluded: TransformWrapperIgnoreClasses
              }}
              onZoom = { updateXarrow }
              onZoomStop = { ( event ) => { updateXarrow(); saveScaleToCookies( event.state.scale ); }}
              onPinching = { updateXarrow }
              onPinchingStart = { updateXarrow }
              onPinchingStop = { updateXarrow }
              onPanning = { updateXarrow }
              onPanningStop = { updateXarrow }

            >

              <TransformComponent>              

                <div id = "space" className = "constructor__space">

                  <GlobalDummy dummy = { dummy } />
                 
                  { tables.length > 0 && 

                    <RenderTables

                      tables = { tables }
                      canEdit = { canEdit }
                      areaScale = { currentScale }
                      renameTable = { handleRenameTable }
                      removeTable = { handleRemoveTable }
                      addColumn = { handleAddColumn }
                      editColumn = { handleEditColumn }
                      removeColumn = { handleRemoveColumn }
                      updateTablePosition = { handleUpdateTablePosition }
                      updateRowsPosition = { handleUpdateRowsPosition }
                      setDisablePinchZoom = { setDisablePinchZoom }
                      setTablesOnTopLayer = { setTablesOnTopLayer }

                    />
                                  
                  }

                </div>

              </TransformComponent>                

              { canEdit &&
              
                <ButtonBigAdd 

                  text = { "Новая таблица" } 
                  action = { handleAddTable } 

                />
              
              }

            </TransformWrapper>
          
          </Xwrapper>

          { tables.length === 0 && 
          
            <div className="fullscreen-tip">
              { canEdit ? "Начните проектирование схемы базы данных - добавьте первую таблицу!" : "В этой схеме ещё нет ни одной таблицы" }
            </div>
                  
          }

          <MessageFromRikky

          title = {(
            <Fragment>
              Я хочу сделать Database Design лучше, удобнее и полезнее для вас!
            </Fragment>
          )}
          text = {(
            <Fragment>
              И только вы можете помочь мне это сделать! Пишите в чат под этим сообщением, <br/>
              что вам нравится и не нравится, что удобно или не удобно, какого функционала <br/>
              не хватает. Что добавить, чтобы этот проект был для вас максимально полезен?
            </Fragment>
          )}
          subtitle = {(
            <Fragment>
              Ваше мнение очень важно для меня! <span>Андрей Рик, создатель проекта.</span>
            </Fragment>
          )}

          />

        </div>

      </Container>

    </Fragment>

  );

}

export default Constructor;