import React from "react";
import { ReactComponent as SchemeIcon } from "./icons/scheme.icon.svg";
import './scheme.row.scss';

const SchemeRow = ({ id, name, description, actionOpen }) => (

  <div className="scheme_row flex items-center" onClick = { () => actionOpen( id ) }>

    <SchemeIcon className="scheme_row__icon relative" />

    <div className="scheme_row__info relative">

      <div className="scheme_row__name">{ name }</div>
      <div className="scheme_row__description">{ description }</div>

    </div>
    
  </div>

);

export default SchemeRow;