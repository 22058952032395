import { post } from 'axios';
import API from '../../settings/api'
import cookie from 'react-cookies';

export async function pay( store, id ) {

  try {

    const token = cookie.load('token');

    const { status, data } = await post( API.tariff.pay, { token, tariff_id: id } );

    if ( status === 200 ) {

      if ( data.success ) {

        window.location = data.redirect;
        
      } else {

        throw( data.text );

      }

    } else {

      throw('Нет соединения с сервером');

    }

  } catch ( err ) {

    console.error( err );

  }
  
}