import { Fragment, useEffect, useRef, useState } from "react";
import { ReactComponent as ConfirmIcon } from '../icons/confirm.svg';
import { DATA_TYPES, DT_GROUP } from "../../../../settings/data.types";
import { FOREIGN_ON_ACTIONS } from "../../../../settings/foreign.on.actions";
import cssIf from "../../../../scripts/helpers/class.add.if";
import Textfield from "../../textfield";
import Checkbox from "../../checkbox";
import Selectfield from "../../selectfield";
import Textarea from "../../textarea";
import { INDEX_METHODS, INDEX_TYPES } from "../../../../settings/index.enums";

const ColumnSettings = ( props ) => {

  const {

    canEdit,
    refDOM,

    name,
    setName,
    refName,

    type,
    handleSetType,

    foreign,
    onDelete,
    setOnDelete,
    onUpdate,
    setOnUpdate,

    defaultValue,
    setDefaultValue,
    refDefaultValue,

    size,
    setSize,
    refSize,

    decimal,
    setDecimal,
    refDecimal,

    isPrimaryKey,
    setIsPrimaryKey,
    
    autoIncrement,
    setAutoIncrement,

    isNotNull,
    setIsNotNull,

    isUnique,
    setIsUnique,

    isIndex,
    setIsIndex,
    indexMethod,
    setIndexMethod,
    indexType,
    setIndexType,

    comment,
    setComment,

    handleAction,
    openTypeSelector,
    openRelationSelector

  } = props;

  const COMMENT_LENGTH_FOR_LARGE_TEXTAREA = 57;

  const commentShortField = useRef( null );
  const commentLongField = useRef( null );

  const [ firstArgFieldTitle, setFirstArgFieldTitle ] = useState( false );
  const [ secondArgFieldTitle, setSecondArgFieldTitle ] = useState( false );
  const [ canBeUnique, setCanBeUnique ] = useState( false );

  const canBeUniqueTypes     = [ 0, 1, 2, 3, 4, 6, 7, 8, 10, 15, 16, 17, 18, 19, 20, 21, 22, 25, 26 ];
  const lengthInSymbolsTypes = [ 1, 21 ];
  const lengthInBytesTypes   = [ 28, 29, 12, 11, 13, 14 ];
  const lengthInMaxNumbers   = [ 9, 10, 15, 16, 17, 18, 19, 20 ];
  const enumTypes            = [ 25, 26 ];


  useEffect(() => {

    let fft = false;
    let sft = false;

    setCanBeUnique( canBeUniqueTypes.includes( type?.id ) );

    if ( lengthInSymbolsTypes.includes( type?.id ) ) { fft = "Длина в символах:" }
    if (   lengthInBytesTypes.includes( type?.id ) ) { fft = "Длина в байтах:" }
    if (   lengthInMaxNumbers.includes( type?.id ) ) { fft = "Количество цифр:" }
    if (            enumTypes.includes( type?.id ) ) { fft = "Допустимые значения через запятую:" }

    setFirstArgFieldTitle( fft );
    sft && setSecondArgFieldTitle( sft );
    
  }, [ type ]);

  useEffect( () => {

    // Если столбец ссылается на другую таблицу, поле комментария занимает половину окна.
    // Однако если комментарий занимает по длине больше двух строк, мы принудительно делаем
    // ширину поля комментария во всё окно. Это достигается использованием двух textarea с условием.
    // Функция ниже нужна для автоматического прыжка курсора из одного textarea в другой, чтобы
    // изменение позиции textarea не мешало печатать комментарий пользователю

    if ( !foreign?.id || comment.length === 0 || !commentShortField.current || !commentLongField.current ) { return; }

    if ( comment.length < COMMENT_LENGTH_FOR_LARGE_TEXTAREA ) {

      if ( commentShortField.current && commentShortField.current !== document.activeElement ) {

        commentShortField.current.focus();
        commentShortField.current?.setSelectionRange( comment.length, comment.length );

      }

    } else {

      if ( commentLongField.current && commentLongField.current !== document.activeElement ) {

        commentLongField.current.focus();
        commentLongField.current?.setSelectionRange( comment.length, comment.length );

      }

    }

  }, [ comment ]);

  function formatEnumValue() {

    if ( size.trim() === "" ) { return; }

    const cleared = size.replace(/["'`]/g, '');
    const parts = cleared.split(",")
    const formatted = parts.map(item => `"${ item.trim() }"`).join(', ');
    
    setSize( formatted );

  }

  return (

    <Fragment>

      <div className = "flex justify-between table__form__content">

        <div className = "table__form__col">

          <Textfield

            title = "Название:"
            value = { name }
            set = { setName }
            refDOM = { refName }
            placeholder = "На англ.языке без пробелов"
            inputClassName = { cssIf( !canEdit, `events-none` ) }

          />

          <div className = "table__form__checkboxs flex">

            <div className = "table__form__checkboxs__col table__form__checkboxs__col--1">

              <Checkbox

                title = "NOT NULL"
                value = { isNotNull }
                set = { setIsNotNull }
                tip = "Не может быть пустым"
                className = { cssIf( !canEdit, `events-none` ) }

              />

              { canBeUnique &&
              
                <Checkbox

                  title = "UNIQUE"
                  value = { isUnique }
                  set = { setIsUnique }
                  tip = "Значение не должно повторяться"
                  className = { cssIf( !canEdit, `events-none` ) }

                />
                
              }

            </div>

            <div>

              <Checkbox

                title = "PRIMARY KEY"
                value = { isPrimaryKey }
                set = { setIsPrimaryKey }
                tip = "Первичный ключ"
                className = { cssIf( !canEdit, `events-none` ) }

              />

              {( isPrimaryKey || autoIncrement ) && 

                <Checkbox

                  title = "AUTO INCREMENT"
                  value = { autoIncrement }
                  set = { setAutoIncrement }
                  tip = "Автозаполнение по нарастающей"
                  className = { cssIf( !canEdit, `events-none` ) }

                />

              }

            </div>
            

          </div>

          <div 
          
            onClick = { e => { 
              
              canEdit && openRelationSelector();
              e.stopPropagation();
              
            }}
            
          >

            <Selectfield

              title = "Связь с другой таблицей:"
              selected = { foreign }
              select = { () => {} }
              list = {[]}
              className = { cssIf( !canEdit, `events-none` ) }

            />

          </div>

          { foreign?.id &&
          
            <div className = "two-inputs flex">

              <Selectfield

                title = "При удалении:"
                selected = { onDelete }
                select = { setOnDelete }
                list = { FOREIGN_ON_ACTIONS }
                placeholder = "NO ACTION"
                className = { cssIf( !canEdit, `events-none` ) }

              />

              <Selectfield

                title = "При обновлении:"
                selected = { onUpdate }
                select = { setOnUpdate }
                list = { FOREIGN_ON_ACTIONS }
                placeholder = "NO ACTION"
                className = { cssIf( !canEdit, `events-none` ) }

              />

            </div>
            
          }

        </div>

        <div className = "table__form__col table__form__col-2">

          <div onClick = { () => canEdit && openTypeSelector() }>
          <Selectfield

            title = "Тип данных:"
            selected = { type }
            select = { handleSetType }
            list = { DATA_TYPES }
            setDisablePinchZoom = { props.setDisablePinchZoom }
            className = {`events-none`}

          />
          </div>

          <div className = "two-inputs flex">

            {( firstArgFieldTitle && firstArgFieldTitle !== "" ) && 
            
              <Textfield

                title = { firstArgFieldTitle }
                value = { size }
                set = { setSize }
                refDOM = { refSize }
                inputClassName = { cssIf( !canEdit, `events-none` ) }
                onBlur = { () => { enumTypes.includes( type?.id ) && formatEnumValue() } }

              />
              
            }

            { type?.group === DT_GROUP.DECIMAL &&
            
              <Textfield

                title = "Дробная часть:"
                //TODO
                value = { decimal }
                set = { setDecimal }
                refDOM = { refDecimal }
                inputClassName = { cssIf( !canEdit, `events-none` ) }

              />
            }

          </div>

          <Textfield

            title = "Значение по умолчанию:"
            value = { defaultValue }
            set = { setDefaultValue }
            refDOM = { refDefaultValue }
            inputClassName = { cssIf( !canEdit, `events-none` ) }
            placeholder = { isNotNull ? "Не обязательно" : "NULL" }

          />

          <Textarea
    
            refDOM = { commentShortField }
            title = "Комментарий:"
            value = { comment }
            set = { setComment }
            placeholder = "Не обязательно"
            className = {`${ cssIf( !canEdit, `events-none` ) } ${ cssIf( !( foreign?.id && comment.length < COMMENT_LENGTH_FOR_LARGE_TEXTAREA ), `hidden` )}`}
    
          />
          
        </div>

      </div>
      
      { isUnique 
      
        ? <p 
            style = {{ marginTop: '-0.02vw', opacity: '0.7' }}
            title = "Индекс ускоряет скорость поиска значений в этом столбце во много раз"
          >
            Этот столбец автоматически индексируется, так как отмечен как отмечен как UNIQUE
          </p> 
          
        : <div>

            <Checkbox

              title = "Индексировать этот столбец"
              value = { isIndex }
              set = { setIsIndex }
              tip = "Индекс создается на поле в таблице по которому будет происходить поиск. Это ускоряет скорость поиска в разы. Также обязательно ставьте индексы для полей участвующих в соединениях таблиц по INNER JOIN, LEFT JOIN, RIGHT JOIN. Поскольку индекс занимает место, то индексировать нужно только те поля, по которым происходит выборка."
              className = { cssIf( !canEdit, `events-none` ) }

            />

            { isIndex && 

              <div className = "flex">

                <Selectfield

                  title = "Метод индекса:"
                  selected = { indexMethod }
                  select = { setIndexMethod }
                  list = { INDEX_METHODS }
                  placeholder = "B-TREE"
                  className = { cssIf( !canEdit, `events-none` ) }

                />

                <Selectfield

                  title = "Тип индекса:"
                  selected = { indexType }
                  select = { setIndexType }
                  list = { INDEX_TYPES }
                  placeholder = "NORMAL"
                  className = { cssIf( !canEdit, `events-none` ) }

                />
                
              </div>

            }

          </div>
      
      }

      <Textarea

        refDOM = { commentLongField }
        title = "Комментарий:"
        value = { comment }
        set = { setComment }
        placeholder = "Не обязательно"
        className = {`${ cssIf( !canEdit, `events-none` ) } ${ cssIf(!( !foreign?.id || comment.length >= COMMENT_LENGTH_FOR_LARGE_TEXTAREA ), `hidden` )}`}

      />

      <div className = "table__add" onClick = { handleAction } ref = { refDOM }>
        <ConfirmIcon />
      </div>

    </Fragment>

  );

}

export default ColumnSettings;