import React, { useState, useEffect, Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import './sign.scss';

import Textfield from "../../components/textfield";
import Button, { ButtonRouter } from "../../components/button";
import Container from "../../components/container";
import useGlobal from "../../../store";
import { validateEmail } from "../../../scripts/helpers/validators";
import Checkbox from "../../components/checkbox";
import { DOCUMENTS } from "../../../settings/constants";
import VerifyCodeField from "../../components/verify.code.field";

const Up = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { sign } = globalActions;
  const navigate = useNavigate();
  const redirect = () => navigate('/');

  const [ email, setEmail ] = useState ("");
  const [ phone, setPhone ] = useState ("");
  const [ password, setPassword ] = useState ("");
  const [ repeat, setRepeat ] = useState ("");  
  const [ offerAccepted, setOfferAccepted ] = useState( false );
  const [ privacyAccepted, setPrivacyAccepted ] = useState( false );
  const [ error, setError ] = useState ("");
  const [ isCodeSended, setIsCodeSended ] = useState( false );

  const emailField    = useRef( null );
  const phoneField    = useRef( null );
  const passwordField = useRef( null );
  const repeatField   = useRef( null );

  const isFormValidated = validateEmail( email ) && password !== "" && password === repeat && offerAccepted && privacyAccepted;

  useEffect( () => { setError(""); }, [ email, password, repeat ]);

  useEffect( () => {

    const passRepeatError = ( 

      validateEmail( email ) 
      && 
      password !== "" 
      && 
      password !== repeat 
      && 
      offerAccepted 
      && 
      privacyAccepted 

    );
    
    setError( passRepeatError ? "Пароли не совпадают!" : "" );

  }, [

    email,
    password,
    repeat,
    offerAccepted,
    privacyAccepted

  ]);

  const handleSendCode = () => { 
    
    error && setError( false );
    sign.Up({ email, phone, password, repeat, onSuccess: () => setIsCodeSended( true ), onError: setError })
  
  };
  
  const handleVerifyCode = code => {
    
    error && setError( false );
    sign.Up({ email, phone, password, repeat, code, onSuccess: redirect, onError: setError })
  
  }

  return (

    <Container>

      <div className = "sign sign--up">

        <div className = "sign__content sign__content-reg">

          { !isCodeSended

            ? <div>

              <Textfield

                title = "Электронная почта"
                type = "email"
                value = { email }
                set = { value => setEmail( value.replace(/[а-яА-ЯёЁ\s]/g, "" ) ) }
                refDOM = { emailField }
                placeholder = "Электронная почта"
                onKeyUp = { e => e.key === 'Enter' && phoneField.current.focus() }

              />

              <Textfield

                title = {(<Fragment>Телефон <span style = {{ opacity: 0.6, paddingLeft: '2px'}}>(не обязательно)</span></Fragment>)}
                type = "tel"
                value = { phone }
                set = { setPhone }
                refDOM = { phoneField }
                placeholder = "+7 999 999-99-99"
                onKeyUp = { e => e.key === 'Enter' && passwordField.current.focus() }

              />

              <Textfield

                title = "Пароль"
                type = "password"
                value = { password }
                set = { setPassword }
                refDOM = { passwordField }
                placeholder = "Введите пароль"
                onKeyUp = { e => e.key === 'Enter' && repeatField.current.focus() }

              />

              <Textfield

                title = "Повторите пароль"
                type = "password"
                value = { repeat }
                set = { setRepeat }
                refDOM = { repeatField }
                placeholder = "Повторите пароль"
                onKeyUp = { e => ( e.key === 'Enter' && isFormValidated ) && handleSendCode() }

              />
              
              <hr />

              { error !== "" && <Fragment><p className="error">{ error }</p><hr /></Fragment> }

              <Checkbox

                title = {

                  <Fragment>
                    Я принимаю условия <a href = { DOCUMENTS.offer } target = "_blank" rel = "norefferer" onClick = {( e ) => { e.stopPropagation(); }}>Договора-оферты</a>
                  </Fragment>
                  
                }
                value = { offerAccepted }
                set = { setOfferAccepted }

              />

              <Checkbox

                title = {
                  <Fragment>
                    Я принимаю условия <a href = { DOCUMENTS.privacy } target = "_blank" rel = "norefferer" onClick = {( e ) => { e.stopPropagation(); }}>Политики обработки персональных данных</a>, и даю согласие на обработку моих персональных данных
                  </Fragment>
                }
                value = { privacyAccepted }
                set = { setPrivacyAccepted }

              />

              <Button
          
                action = { handleSendCode }
                disabled = { !isFormValidated }

              >
                Зарегистрироваться

              </Button>

              <p className="text-center pointer" onClick = { () => { navigate('/signin') } }>у меня уже есть аккаунт</p>

              <ButtonRouter url = "/signin">Авторизоваться</ButtonRouter>
          
              </div>

          : <VerifyCodeField

              resendCode = { handleSendCode }
              verifyCode = { handleVerifyCode }
              setError = { setError }
              error = { error }

            />

          }

        </div>

      </div>

    </Container>

  );

}

export default Up;