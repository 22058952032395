import { Fragment, useEffect, useRef, useState } from "react";
import Textfield from "../textfield";
import { Loading } from "../loading";
import "./verify.code.field.scss";

const VerifyCodeField = ( props ) => {

  const {

    resendCode = () => {},
    verifyCode = () => {},
    setError = () => {},
    error = false,
    className = ''

  } = props;

  const n1r = useRef( null );
  const n2r = useRef( null );
  const n3r = useRef( null );
  const n4r = useRef( null );

  const [ n1v, setN1v ] = useState("");
  const [ n2v, setN2v ] = useState("");
  const [ n3v, setN3v ] = useState("");
  const [ n4v, setN4v ] = useState("");

  const [ isLoading, setIsLoading ] = useState( false );

  const lastChar = str => str.length === 0 ? "" : str.charAt( str.length - 1 );

  const focusAtFirstChar = ( delay = 100 ) => setTimeout( () => { n1r?.current?.focus() }, delay );
  
  useEffect(() => { focusAtFirstChar() }, []);

  function clear() {

    setN1v("");
    setN2v("");
    setN3v("");
    setN4v("");

  }

  function send( code ) {

    verifyCode( code );
    setIsLoading( true );

  }

  useEffect(() => {

    if ( !error ) { return; }

    clear();
    setIsLoading( false );
    focusAtFirstChar( 500 );

  }, [ error ]);

  return (

    <div className = {`vcf__container relative ${ className }`}>

      { isLoading ? <Loading withoutText /> :

        <Fragment>

          <div className = "vcf__title text-center">Введите код подтверждения</div>
          <div className = "vcf__subtitle text-center">Письмо с кодом отправлено на ваш e-mail</div>

          <div className = {`vcf__code flex justify-between items-center`}>

            <Textfield

              type = "number"
              refDOM = { n1r }
              value = { n1v }
              set = { char => { 

                setN1v( lastChar( char ) );
                error && setError( false );
                char !== "" && n2r.current.focus();

              }}
              onKeyUp = { e => e.key === 'Backspace' && setN1v("") }

            />

            <Textfield

              type = "number"
              refDOM = { n2r }
              value = { n2v }
              set = { char => { 

                setN2v( lastChar( char ) );
                char !== "" && n3r.current.focus();

              }}
              onKeyUp = { e => {
                
                if ( e.key === 'Backspace' ) { 
                  
                  setN1v("");
                  setN2v("");
                  n1r.current.focus();
                
                }
              
              }}

            />

            <Textfield

              type = "number"
              refDOM = { n3r }
              value = { n3v }
              set = { char => { 

                setN3v( lastChar( char ) );
                char !== "" && n4r.current.focus();

              }}
              onKeyUp = { e => {
                
                if ( e.key === 'Backspace' ) { 
                  
                  setN2v("");
                  setN3v("");
                  n2r.current.focus();
                
                }
              
              }}

            />

            <Textfield

              type = "number"
              refDOM = { n4r }
              value = { n4v }
              set = { char => { 

                setN4v( lastChar( char ) );
                if ( char === "" ) { return; }
                send(`${ n1v }${ n2v }${ n3v }${ char }`);

              }}
              onKeyUp = { e => {
                
                if ( e.key === 'Backspace' ) { 
                  
                  setN3v("");
                  setN4v("");
                  n3r.current.focus();
                
                }
              
              }}

            />

          </div>

          <ResendController action = { resendCode } />

          { error && <p className="error">{ error }</p> }

        </Fragment>
      
      }

    </div>

  );

}

const ResendController = ({ action = () => {} }) => {

  const waitSeconds = 60;
  const [ timeBeforeResend, setTimeBeforeResend ] = useState( waitSeconds );
  const [ resendCounter, setResendCounter ] = useState( 0 );

  useEffect(() => {

    if ( timeBeforeResend === 0 ) { return; }
    setTimeout(() => { setTimeBeforeResend( timeBeforeResend - 1 ) }, 1000 );

  }, [ timeBeforeResend ])

  function handleResend() {

    setResendCounter( resendCounter + 1 );
    setTimeBeforeResend( waitSeconds );
    action();

  }

  return (
    
    <div className = "vcf__resend">

      { resendCounter < 3 
      
        ? <Fragment>

            { timeBeforeResend !== 0
            
              ? <div className = "vcf__subtitle text-center">запросить повторную отправку <br/>можно через { timeBeforeResend } секунд</div>
              : <div className = "vcf__subtitle text-center pointer underline" onClick = { handleResend }>отправить код повторно</div>

            }

          </Fragment>

        : <div className = "vcf__subtitle text-center">вы превысили лимит отправок кода</div>

      }

      { resendCounter >= 2 && <div className = "vcf__subtitle vcf__ifnothingcode text-center">если код на почту не приходит, напишите <br/>об этом в чат на главной странице сайта<br/><a href="https://database-design.ru" target="_blank" rel="noreferrer noopener" className = "underline">database-design.ru</a>, указав свой e-mail</div> }

    </div>

  );

}

export default VerifyCodeField;