import { initialPopupStates } from "..";

async function show( store, name ) {

  store.setState({

    popup_opened: name

  })

}

async function hide( store ) {

  store.setState({ 
    popup_animate_close: true 
  })

  setTimeout(() => {

    store.setState( initialPopupStates )

  }, 600)

}

async function confirm( store, params ) {

  const { 
    
    title = "Вы уверены?",
    text = "Это действие необратимо!",
    icon = "",
    action = () => {}
  
  } = params;

  store.setState({

    popup_opened: "confirm",
    popup_confirm_title: title,
    popup_confirm_text: text,
    popup_confirm_icon: icon,
    popup_confirm_function: action

  })

}

export {

  show,
  hide,
  confirm

}