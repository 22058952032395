const THEME = {

  DARK: 1,
  LIGHT: 2

}

const DOCUMENTS = {

  offer: `https://database-design.ru/offer/`,
  privacy: `https://database-design.ru/privacy/`

}

const TARIFF = {

  MONTH: 1,
  YEAR: 2

}

const SHARE = {

  ONLY_ME: 1,
  PUBLIC:  2,
  EMAILS:  3

}

const SHARE_TEXT = [ "Только я", "Все", "Указать E-mail" ];

const SHARE_ACCESS = {

  READ: 1,
  EDIT: 2

}

const SHARE_ACCESS_LIST = [ 
  
  {
    id: SHARE_ACCESS.READ,
    name: "читатель"
  }, 
  
  {
    id: SHARE_ACCESS.EDIT,
    name: "редактор",
    disabled: true
  }

]

export {
  
  THEME,
  DOCUMENTS,
  TARIFF,
  SHARE, 
  SHARE_TEXT,
  SHARE_ACCESS,
  SHARE_ACCESS_LIST

}